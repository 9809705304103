import { ChatHistoryType } from '@/types/converse/chatHistory';

const groupChatHistory = (chatHistory: ChatHistoryType[]) => {
  const now = new Date();

  const groups: {
    today: ChatHistoryType[];
    yesterday: ChatHistoryType[];
    'last 7 Days': ChatHistoryType[];
    'last 30 Days': ChatHistoryType[];
    older: ChatHistoryType[];
  } = {
    today: [],
    yesterday: [],
    'last 7 Days': [],
    'last 30 Days': [],
    older: [],
  };

  console.log('Chat history received = ');
  console.log(chatHistory);
  (chatHistory ?? []).forEach((chat) => {
    const chatDate = new Date(chat.timestamp);
    const diffDays = differenceInDays(now, chatDate);

    if (diffDays === 0) {
      groups.today.push(chat);
    } else if (diffDays === 1) {
      groups.yesterday.push(chat);
    } else if (diffDays <= 7) {
      groups['last 7 Days'].push(chat);
    } else if (diffDays <= 30) {
      groups['last 30 Days'].push(chat);
    } else {
      groups.older.push(chat);
    }
  });

  return groups;
};

function differenceInDays(now: Date, chatDate: Date) {
  return Math.floor(
    (now.getTime() - chatDate.getTime()) / (1000 * 60 * 60 * 24)
  );
}

export default groupChatHistory;
