

import SlideUpTransition from '@/components/animations/transition/SlideUp';
import ComingSoon from '@/components/ComingSoon';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useScreenDimensions from '@/hooks/useScreenDimensions';
import ChatOutlinedIcon from '@mui/icons-material/ChatBubbleOutline';
import { Avatar, Box, Dialog, Fab, TextareaAutosize } from '@mui/material';
import { useState } from 'react';

const AlchemystCopilotInput = () => {
  return <TextareaAutosize />;
};

const AlchemystCopilotResponseButton = () => {
  return (
    <Fab>
      <ChatOutlinedIcon />
    </Fab>
  );
};

const AlchemystCopilot = () => {
  const alchemyst = useAlchemystStoreForAi((store) => store.alchemyst);
  const [showButton, setShowButton] = useState(false);

  const windowDimensions = useScreenDimensions();

  const toggleDialog = () => setShowButton((prevState) => !prevState);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: windowDimensions.height * 0.025,
        right: windowDimensions.width * 0.025,
      }}
    >
      <Fab onClick={toggleDialog} variant="circular">
        <Avatar
          src={alchemyst.avatarUrl}
          alt={`${alchemyst.persona}-avatar`}
          sx={{
            alignSelf: 'center',
            marginY: 1,
            width: Math.min(
              windowDimensions.height * 0.0625,
              windowDimensions.width * 0.0625
            ),
            height: Math.min(
              windowDimensions.height * 0.0625,
              windowDimensions.width * 0.0625
            ),
          }}
        />
      </Fab>

      <Dialog
        open={showButton}
        TransitionComponent={SlideUpTransition}
        onClose={toggleDialog}
        fullWidth
        // fullScreen
      >
        <Box
          borderRadius={20}
          padding={5}
          height={windowDimensions.height * 0.8}
        >
          <ComingSoon message="Alchemyst copilot" />
        </Box>
      </Dialog>
    </Box>
  );
};

export default AlchemystCopilot;
