import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { Email } from '@/types/email';
import { useRef, useState } from 'react';
import { fetchWithRewrites } from '../fetchWithRewrites';
import { createTimeoutSignal } from '../signalConfig';

const useEmailFetcher = () => {
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const [done, setDone] = useState<boolean>(false);
  const { emailBodiesByThreadByEmail, threadIds, snackbarEntries } =
    useAlchemystStoreForAi((store) => {
      return {
        emailBodiesByThreadByEmail: store.emailBodiesByThreadByEmail,
        threadIds: store.threadIds,
        snackbarEntries: store.snackbarEntries,
      };
    });

  // useRef to track whether the selected thread has already been set
  const isSelectedThreadSet = useRef(false);

  const fetchEmailBodies = async (
    emailsToCheck: string[],
    threadIds: string[],
    addNew: boolean = false
  ) => {
    console.log('Fetching email bodies...');
    if (!threadIds.length || !emailsToCheck.length) return;

    const emailData: Record<string, Record<string, Email[]>[]> = {};

    setStoreState({ progressLoading: 0 });
    setDone(false);

    if (!addNew) {
      setStoreState({ emailBodiesByThreadByEmail: {} });
      isSelectedThreadSet.current = false;
    }

    const MAX_RETRIES = 5;
    let retryCount = 0;
    let fetchCompleted = false;
    let completedThreadIds: string[] = [];

    while (retryCount <= MAX_RETRIES && !fetchCompleted) {
      try {
        // Create an array of fetch promises for parallel execution
        const fetchPromises = threadIds
          .filter(
            (threadIdEntry) => !completedThreadIds.includes(threadIdEntry)
          )
          .map(async (threadId, index) => {
            if (completedThreadIds.includes(threadId)) {
              return;
            }
            const response = await fetchWithRewrites('/api/inbox/thread', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ threadId }),
              signal: createTimeoutSignal(),
            });

            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            } else {
              completedThreadIds.push(threadId);
            }

            const threadData = await response.json();
            const emails = threadData.emails;
            const { from, to } = emails[0];

            // Update email data for "from" and "to" fields
            [from, to].forEach((email) => {
              if (emailsToCheck.includes(email)) {
                if (!emailData[email]) emailData[email] = [];
                if (
                  !emailData[email].some((entry) =>
                    entry.hasOwnProperty(threadId)
                  )
                ) {
                  emailData[email].push({ [threadId]: emails });
                }
              }
            });

            // Update store with loaded data immediately
            const partialEmailBodiesByThreadByEmail = {
              ...emailBodiesByThreadByEmail,
              ...emailData,
            };

            setStoreState({
              emailBodiesByThreadByEmail: partialEmailBodiesByThreadByEmail,
            });

            // Set the selected thread to the first fetched thread only once
            if (!isSelectedThreadSet.current) {
              if (
                emails &&
                emails.length > 0 &&
                (emailsToCheck.includes(from) || emailsToCheck.includes(to))
              ) {
                setStoreState({
                  selectedThread: threadId,
                  preparingThreadLoading: false,
                });
                isSelectedThreadSet.current = true;
              }
            }

            // Set progress after each thread is fetched
            setStoreState({
              progressLoading: Math.round(
                (100 * (index + 1)) / threadIds.length
              ),
            });

            return threadId; // Return the threadId for potential selection
          });

        await Promise.all(fetchPromises);
        setDone(true);
        fetchCompleted = true;
      } catch (err) {
        retryCount++;
      }
    }
    if (!fetchCompleted) {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'warning',
            message: `Failed to fetch some emails.`,
            type: 'alert',
            autoHideDuration: 6000,
            variant: 'determinate',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      setStoreState({
        preparingThreadLoading: false,
      });
    }
  };

  const deleteEmailBodies = (emailsToDelete: string[]) => {
    const updatedEmailBodiesByThreadByEmail = Object.fromEntries(
      Object.entries(emailBodiesByThreadByEmail).filter(
        ([email]) => !emailsToDelete.includes(email)
      )
    );

    setStoreState({
      emailBodiesByThreadByEmail: updatedEmailBodiesByThreadByEmail,
      selectedThread: threadIds[0] ?? '',
    });
  };

  return {
    done,
    deleteEmailBodies,
    fetchEmailBodies,
  };
};

export default useEmailFetcher;
