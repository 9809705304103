import AutoEmailLayout from '@/components/Emails/AutoEmailLayout';
import InboxLayout from '@/components/Inbox/InboxLayout';
import AppLayout from '@/components/layout/AppLayout';
import ProfilePage from '@/components/profile/page';
import SignInPage from '@/pages/signIn/page';
import { useNavigate } from 'raviger';
import AugmentComponent from '../components/Augment';
import CampaignParent from '../components/Campaigns/CampaignParent';
import { ChatMainLayout } from '../components/Converse';
import Dashboard from '../components/Dashboard/Dashboard';
import InstructionsComponent from '../components/Instructions/InstructionsComponent';
import Integrations from '../components/Integrations/Integrations';
import LeadsComponent from '../components/Leads/LeadsComponent';
import Prospector from '../components/Prospector/manual';
import SettingFields from '../components/Smart-setting/SmartSettingFields';
import SmartSettingsPage from '../components/Smart-setting/SmartSettingPage';
import PageNotFound from '../pages/not-found/page';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  const match = document.cookie.match(/(^| )accessToken=([^;]+)/);

  if (!match) {
    navigate('/auth');
  }

  return <AppLayout>{children}</AppLayout>;
};

export const routes = {
  '/auth': () => <SignInPage />,
  '/': () => (
    <ProtectedRoute>
      <div>something</div>
      {/* <UserButon /> */}
    </ProtectedRoute>
  ),
  '/augment': () => (
    <ProtectedRoute>
      <AugmentComponent />
    </ProtectedRoute>
  ),
  '/campaigns': () => (
    <ProtectedRoute>
      <CampaignParent />
    </ProtectedRoute>
  ),
  '/inbox': () => (
    <ProtectedRoute>
      <InboxLayout />
    </ProtectedRoute>
  ),
  '/automated-emails': () => (
    <ProtectedRoute>
      <AutoEmailLayout />
    </ProtectedRoute>
  ),
  '/settings': () => (
    <ProtectedRoute>
      <SettingFields />
    </ProtectedRoute>
  ),
  '/converse': () => (
    <ProtectedRoute>
      <ChatMainLayout />
    </ProtectedRoute>
  ),
  '/dashboard': () => (
    <ProtectedRoute>
      <Dashboard />
    </ProtectedRoute>
  ),
  '/integrations': () => (
    <ProtectedRoute>
      <Integrations />
    </ProtectedRoute>
  ),
  '/prospector': () => (
    <ProtectedRoute>
      <Prospector />
    </ProtectedRoute>
  ),
  '/leads': () => (
    <ProtectedRoute>
      <LeadsComponent />
    </ProtectedRoute>
  ),
  '/smart-setting': () => (
    <ProtectedRoute>
      <SmartSettingsPage />
    </ProtectedRoute>
  ),
  // '/workx': () => (
  //   <ProtectedRoute>
  //     <Work />
  //   </ProtectedRoute>
  // ),
  '/instructions': () => (
    <ProtectedRoute>
      <InstructionsComponent />
    </ProtectedRoute>
  ),
  '/profile': () => (
    <ProtectedRoute>
      <ProfilePage />
    </ProtectedRoute>
  ),
  '*': () => <PageNotFound />,
};
