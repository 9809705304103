/* ----------------- Web Speech Synthesis API Util --------------- */

/**
 * Converts a given text to speech using the Web Speech Synthesis API.
 * @param {string} text - The text to be converted to speech.
 * @param {SpeechSynthesisUtterance} [utterance] - Optional custom SpeechSynthesisUtterance object for more control.
 */
export const speakText = (
  text: string,
  utterance: SpeechSynthesisUtterance = new SpeechSynthesisUtterance()
) => {
  if (!window.speechSynthesis) {
    console.error('Speech Synthesis not supported in this browser.');
    return;
  }

  // Set the text to be spoken
  utterance.text = text;

  // Speak the text
  window.speechSynthesis.speak(utterance);
};

/**
 * Checks if the current browser supports the Web Speech Synthesis API.
 * @returns {boolean} True if the browser supports the API, false otherwise.
 */
export const isSpeechSynthesisSupported = (): boolean => {
  return 'speechSynthesis' in window;
};
