/**
 * Converts user's voice input to text using the Web Speech API.
 * @returns {Promise<string>} A promise that resolves to the transcribed text.
 */
export const convertSpeechToText = async (): Promise<string> => {
  // Check if the browser supports the Web Speech API
  const SpeechRecognition =
    (window as any).SpeechRecognition ||
    (window as any).webkitSpeechRecognition;

  if (!SpeechRecognition) {
    throw new Error('Web Speech API is not supported in this browser.');
  }

  const recognition = new SpeechRecognition();
  recognition.continuous = false; // Stop automatically after a single result
  recognition.interimResults = false; // Return only final results
  recognition.lang = 'en-US'; // Set the language
  recognition.maxAlternatives = 1;

  return new Promise((resolve, reject) => {
    recognition.onstart = () => {
      console.log(
        'Speech recognition started. Please speak into the microphone.'
      );
    };

    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      reject(new Error(`Speech recognition error: ${event.error}`));
    };

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      console.log(event);
      const result = event.results[0][0].transcript;
      resolve(result);
    };

    recognition.onend = () => {
      console.log('Speech recognition ended.');
    };

    recognition.start();
  });
};

/**
 * Checks if the current browser supports the SpeechRecognition API.
 * @returns {boolean} True if the browser supports the API, false otherwise.
 */
export const isSpeechRecognitionSupported = (): boolean => {
  const SpeechRecognition =
    (window as any).SpeechRecognition ||
    (window as any).webkitSpeechRecognition;

  return !!SpeechRecognition;
};
