import { LicenseInfo } from '@mui/x-license';

const REACT_APP_MUI_X_LICENSE_KEY = process.env.REACT_APP_MUI_X_LICENSE_KEY;
if (!REACT_APP_MUI_X_LICENSE_KEY) {
  throw new Error('REACT_APP_MUI_X_LICENSE_KEY is not set');
}

LicenseInfo.setLicenseKey(REACT_APP_MUI_X_LICENSE_KEY);
export default function MuiXLicense() {
  return null;
}
