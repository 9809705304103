
import { IOSSwitch } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { IntegrationsEntry } from '@/types/integrations';
import { Settings } from '@mui/icons-material';
import {
    Box,
    Chip,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { useState } from 'react';

type IntegrationCardProps = {
  item: IntegrationsEntry;
};

const IntegrationCard: React.FC<IntegrationCardProps> = ({ item }) => {
  const [enabled, setEnabled] = useState(item.enabled);
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
        padding: 3,
        paddingX: 6,
        marginBottom: 2,
        borderRadius: 2,
        background:
          'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
      }}
      className={
        currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
        <img src={item.icon} alt={item.name} width={80} height={80} />
        <Box marginLeft={2}>
          <Typography variant="h4">{item.name}</Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ marginTop: 1, width: '90%' }}
          >
            {item.description}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginTop: 1, flexWrap: 'wrap' }}
          >
            {item.tags &&
              item.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  color="secondary"
                  variant="outlined"
                />
              ))}
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          marginTop: { xs: 2, md: 0 },
        }}
      >
        <IOSSwitch
          checked={enabled}
          onChange={() => setEnabled(!enabled)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <IconButton disabled={!enabled} color="primary" aria-label="settings">
          <Settings fontSize={'large'} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default IntegrationCard;
