import { DrawerComponentProps } from '../types/drawer';
// import InfoIcon from "@mui/icons-material/Info";
// import SettingsIcon from "@mui/icons-material/Settings";
// import PeopleIcon from "@mui/icons-material/People";
// import ArticleIcon from "@mui/icons-material/Article";

export const mainDrawerComponents: DrawerComponentProps[] = [
  {
    name: 'Dashboard',
    id: 'dashboard',
    path: '/dashboard',
    // icon: <SpaceDashboardIcon />,
  },
  {
    name: 'Converse',
    id: 'converse',
    path: '/converse',
    // icon: <MarkUnreadChatAltIcon />,
  },
  {
    name: 'Leads',
    id: 'leads',
    path: '/leads',
    // icon: <SettingsAccessibilityIcon />,
  },
  {
    name: 'Campaigns',
    id: 'campaigns',
    path: '/campaigns',
  },
  {
    name: 'Inbox',
    id: 'shopping_cart',
    path: '/inbox',
    // icon: <AllInboxIcon />,
  },
  {
    name: 'Automated Emails',
    id: 'automated_emails',
    path: '/automated-emails',
    // icon: <MailIcon />,
  },
  {
    name: 'Prospector',
    id: 'recommendations',
    path: '/prospector',
    // icon: <LightbulbIcon />,
  },
  // {
  //   name: "Augment",
  //   id: "data-augmentor",
  //   path: "/augment",
  //   icon: <ArticleIcon />,
  // },
  {
    name: 'Integrations',
    id: 'integrations',
    path: '/integrations',
    // icon: <LinkIcon />,
  },
  // {
  //   name: "Instructions",
  //   id: "instructions",
  //   path: "/instructions",
  //   icon: <InfoIcon />,
  // },
  // {
  //   name: "Users",
  //   id: "people",
  //   path: "/users",
  //   icon: <PeopleIcon />,
  // },
  {
    name: 'Smart Setting',
    id: 'smart-settings',
    path: '/smart-setting',
    // icon: <SettingsSuggestIcon />,
  },
  {
    name: 'Settings',
    id: 'settings',
    path: '/settings',
    // icon: <Settings />,
  },
];

export const settingsDrawerComponents: DrawerComponentProps[] = [
  {
    name: 'API Keys',
    id: 'api-keys',
    path: '/settings/api-keys',
  },
  {
    name: 'Billing',
    id: 'billing',
    path: '/settings/billing',
  },
  {
    name: 'Usage',
    id: 'usage',
    path: '/settings/usage',
  },
];

export const integrationsDrawerComponents: DrawerComponentProps[] = [
  {
    name: 'My integrations',
    id: 'my-integrations',
    path: '/integrations',
  },
  {
    name: 'Slack',
    id: 'slack',
    path: '/integrations/slack',
  },
  {
    name: 'Discord',
    id: 'discord',
    path: '/integrations/discord',
  },
  {
    name: 'Telegram',
    id: 'telegram',
    path: '/integrations/telegram',
  },
  {
    name: 'Google Workspace',
    id: 'ms-teams',
    path: '/integrations/google-workspace',
  },
];

export const navbarComponents: DrawerComponentProps[] = [
  {
    name: 'Dashboard',
    id: 'dashboard',
    path: '/dashboard',
  },
  {
    name: 'Integrations',
    id: 'integrations',
    path: '/integrations',
  },
  {
    name: 'Docs',
    id: 'docs',
    path: '/docs',
  },
];
