import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Button, Toolbar } from '@mui/material';
import AuthButton from './AuthButton';

const NavBar = () => {
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const isNavbarDrawerOpen = useAlchemystStoreForAi(
    (store) => store.isNavBarDrawerOpen
  );

  return (
    <AppBar
      className="custom-navbar"
      sx={(theme) => ({
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.background.paper,
      })}
    >
      <Toolbar>
        <Button
          onClick={() =>
            setStoreState({ isNavBarDrawerOpen: !isNavbarDrawerOpen })
          }
        >
          <MenuIcon />
        </Button>
        <img
          src="https://alchemyst-updated.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FAI.e605ee6d.png&w=1080&q=75"
          alt="logo"
          width={200}
          height={70}
          className={`${currentTheme === 'dark' ? '' : 'invert'}`}
        />
        <div style={{ flexGrow: 1 }} />
        <AuthButton />
        <Button
          variant="text"
          color={currentTheme === 'dark' ? 'inherit' : 'primary'}
          sx={{
            borderRadius: 100,
            marginX: 2,
          }}
          onClick={() => {
            setStoreState({
              theme: currentTheme === 'dark' ? 'light' : 'dark',
            });
            setStoreState({
              theme: currentTheme === 'dark' ? 'light' : 'dark',
            });
          }}
        >
          {currentTheme === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
