import {
  AIMessage,
  HumanMessage,
  SystemMessage,
  aiMessageSchema,
  humanMessageSchema,
  systemMessageSchema,
} from '@/types/ai/dropInLangchain';
import { LangChainJSON } from '@/types/ai/messages';
import { Message } from '@/types/converse/message';

export const createLangchainMessageFromDBMessages = (messages: Message[]) => {
  return messages.map((message) => {
    const args = {
      content: message.message,
      additional_kwargs: {
        id: message.id ?? crypto.randomUUID(),
        json: message.json,
      },
    };
    console.log('Message role found = ', message.role);
    if (message.role === 'assistant') {
      return aiMessageSchema.parse({ ...args, type: 'assistant' });
    } else if (message.role === 'user') {
      return humanMessageSchema.parse({ ...args, type: 'user' });
    } else {
      return systemMessageSchema.parse({ ...args, type: 'system' });
    }
  });
};

export const createMessageFromLangchainJson = (
  chatHistoryReceived: LangChainJSON[]
) => {
  console.log('Chat history received = ');
  console.log(chatHistoryReceived);
  let chatHistory = chatHistoryReceived.map((chatMessage) => {
    const chatType = (chatMessage.id ?? []).pop()?.toLowerCase();
    const messageContent = chatMessage.lc_kwargs.content;

    if (chatType?.includes('system')) {
      return systemMessageSchema.parse({ content: messageContent });
    } else if (chatType?.includes('user')) {
      return humanMessageSchema.parse({ content: messageContent });
    } else {
      return aiMessageSchema.parse({ content: messageContent });
    }
  });

  return chatHistory;
};

export const createDBMessageFromLangchainMessage = (
  messages: (AIMessage | HumanMessage | SystemMessage)[]
): Message[] => {
  return messages.map((langchainMessage) => {
    let role: 'user' | 'assistant' | 'system' = langchainMessage.type;
    let message = langchainMessage.content.toString();
    return {
      id: '',
      json: '{}',
      message,
      role,
    };
  });
};

export const createLangchainJsonFromLangchainMessage = (
  messages: (AIMessage | HumanMessage | SystemMessage)[]
): LangChainJSON[] => {
  return messages.map((message) => ({
    id: [],
    lc: 0,
    type: message.type,
    lc_kwargs: {
      content: message.content,
      additional_kwargs: message.additional_kwargs ?? {},
      response_metadata: {},
      tool_calls: [], // These can remain optional unless otherwise specified
      invalid_tool_calls: [],
    },
  }));
};
