import { RightSidePanel } from '@/components/common/Sidepanel';
import { ChatHistoryType } from '@/types/converse/chatHistory';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { ChatHistoryContainer } from './history';
import { ChatMessagesContainer } from './messages';

/**
 * ChatMainLayout component
 *
 * This component is the main layout for the chat application.
 * It contains the chat history panel on the right side and the main chat messages area.
 * The chat history panel can be toggled open and closed, and it displays the chat history.
 */
const ChatMainLayout = () => {
  const [open, setOpen] = useState<boolean>(true); // Controls the visibility of the side panel
  const [chatHistoryLoading, setChatHistoryLoading] = useState<boolean>(false);
  const [currentChatID, setCurrentChatID] = useState<string>('');
  const [chatHistory, setChatHistory] = useState<ChatHistoryType[]>([]);
  const panelWidth = '20vw'; // Width of the right-side panel

  /**
   * Fetches chat history data from the API and updates state.
   * Memoized with `useCallback` to avoid unnecessary re-renders.
   */
  const fetchHistory = useCallback(async () => {
    try {
      setChatHistoryLoading(true);
      const chatHistoryResponse = await fetchWithRewrites('/api/chat/history', {
        signal: createTimeoutSignal(),
      });
      const chatHistoryJson: { chats: ChatHistoryType[] } =
        await chatHistoryResponse.json();
      console.log('Received chat history from /api/chat/history = ');
      console.log(chatHistoryJson.chats ?? []);
      setChatHistory(chatHistoryJson.chats ?? []);
    } catch (error) {
      setChatHistory([]);
    } finally {
      setChatHistoryLoading(false); // Always end loading state
    }
  }, []);

  // Fetch chat history on initial render and when `fetchHistory` changes
  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  return (
    <Box>
      {/* Right side panel containing chat history */}
      <RightSidePanel open={open} setOpen={setOpen} panelWidth={panelWidth}>
        <ChatHistoryContainer
          chatHistory={chatHistory}
          currentChatID={currentChatID}
          setCurrentChatID={setCurrentChatID}
          loading={chatHistoryLoading}
        />
      </RightSidePanel>

      {/* Main content area that adjusts based on sidebar visibility */}
      <Box
        marginRight={open ? panelWidth : 0}
        maxWidth={open ? `calc(100% - ${panelWidth})` : '100%'}
      >
        <ChatMessagesContainer
          currentChatID={currentChatID}
          setCurrentChatID={setCurrentChatID}
          setChatHistory={setChatHistory}
        />
      </Box>
    </Box>
  );
};

export default ChatMainLayout;
