import { useSession } from '@/hooks/useSession';
import Typography from '@mui/material/Typography/Typography';
import { useNavigate } from 'raviger';

const ProfilePage = () => {
  const userData = useSession();
  const navigate = useNavigate();

  if (userData.status === 'loading') {
    return <Typography variant="body1">Loading...</Typography>;
  } else if (userData.status === 'error') {
    navigate('/');
  }

  return (
    <div>
      {userData.data && (
        <>
          <img
            src={userData.data?.picture || '/next.svg'}
            alt="Profile Image"
            width={100}
            height={100}
          />
          <p>Name: {userData.data.given_name}</p>
          <p>Email: {userData.data.email}</p>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
