import {
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Dispatch, FC, SetStateAction } from 'react';

// Interface for RightSidePanel component props
interface RightSidePanelProps {
  /** Content to be displayed within the panel */
  children: React.ReactNode;

  /** Width of the panel when open (default: "20vw") */
  panelWidth?: string;

  /** Boolean indicating whether the panel is open */
  open: boolean;

  /** Function to toggle the panel open/close state */
  setOpen: Dispatch<SetStateAction<boolean>>;
}

// ToggleButton Component - controls the open/close state of the panel
const ToggleButton: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ open, setOpen }) => (
  <IconButton
    onClick={() => setOpen(!open)}
    aria-label={open ? 'Close chat sidebar' : 'Open chat sidebar'}
    sx={{
      cursor: 'pointer',
      color: 'white',
      borderRadius: '10px 0 0 10px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: '0.2rem',
      width: '2vw',
    }}
  >
    {open ? (
      <KeyboardDoubleArrowRightRounded sx={{ fontSize: '2rem' }} />
    ) : (
      <KeyboardDoubleArrowLeftRounded sx={{ fontSize: '2rem' }} />
    )}
  </IconButton>
);

/**
 * RightSidePanel component
 *
 * This component renders a right-side panel that can be toggled open or closed.
 * It uses Material-UI's Drawer component for the panel and includes a toggle button
 * to control the open/close state.
 *
 * @component
 * @param {React.ReactNode} children - Content to be displayed within the panel.
 * @param {string} [panelWidth="20vw"] - Width of the panel when open.
 * @param {boolean} open - Boolean indicating whether the panel is open.
 * @param {Dispatch<SetStateAction<boolean>>} setOpen - Function to toggle the panel open/close state.
 *
 * @example
 * <RightSidePanel open={isOpen} setOpen={setIsOpen}>
 *   <div>Your content here</div>
 * </RightSidePanel>
 */

const RightSidePanel: FC<RightSidePanelProps> = ({
  children,
  panelWidth = '20vw',
  open,
  setOpen,
}) => {
  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open
      sx={{
        '& .MuiDrawer-paper': {
          width: open ? panelWidth : 40,
          backgroundColor: 'transparent',
          border: 'none',
        },
      }}
    >
      {/* Main container for the right-side panel, controlling overall layout and styling */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: open ? panelWidth : '2vw',
          overflowX: 'hidden',
          transition: open ? 'revert-layer' : 'width 0.5s',
        }}
      >
        {/* Toggle Button to control panel visibility */}
        <ToggleButton open={open} setOpen={setOpen} />

        {/* Scrollable Content Area within the panel */}
        <Box
          sx={{
            width: `calc(${panelWidth} - 2vw)`,
            height: '100vh',
            maxHeight: '100vh',
            paddingTop: 10,
            overflowY: 'auto',
            border: '3px solid',
            borderRadius: 2,
            background:
              'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
            borderImageSource:
              'linear-gradient(90deg, #ff9a3520 1.52%, #000 90.82%)',
            borderImageSlice: 1,
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default RightSidePanel;
