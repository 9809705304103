import { sampleIntegrationsMenu } from '@/constants';
import { Box, Typography } from '@mui/material';
import IntegrationCard from './IntegrationCard';

const Integrations = () => {
  const installedIntegrations = sampleIntegrationsMenu.filter(
    (item) => item.installed
  );

  return (
    <Box>
      <Typography variant="h6" marginY={3}>
        Installed {installedIntegrations.length}/{sampleIntegrationsMenu.length}{' '}
        integrations.
      </Typography>
      <Box>
        {installedIntegrations.map((item, index) => (
          <IntegrationCard key={index} item={item} />
        ))}
      </Box>
    </Box>
  );
};

export default Integrations;
