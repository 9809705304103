'use client';
import BreadcrumbsBar from '@/components/BreadcrumbsBar';
import LeftDrawer from '@/components/Drawer/LeftDrawer';
import NavBar from '@/components/NavBar';
import AlchemystCopilot from '@/components/ai-native/AlchemystCopilot';
import useLogging from '@/hooks/useLogging';
import { Log } from '@/types/logging';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { usePath } from 'raviger';
import { useEffect } from 'react';
import '../../globals.css';

const drawerWidth = '17%';

const styles = {
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  // toolbar: theme.mixins.toolbar,
  toolbar: {
    minWidth: '100px',
  },
};

export default function AppLayout({ children }: { children: React.ReactNode }) {
  const classes = styles;
  const currentPath = usePath();

  const { getLogs } = useLogging();

  const sendLogsToBackend = (logs: Log[]) => {
    // TODO: Send request to backend.
  };

  useEffect(() => {
    if (window) {
      window.onbeforeunload = (e) => {
        e.preventDefault();
        const logs = getLogs();
        console.log('Saving logs...');

        const sendLogs = async () => {
          const response = await fetchWithRewrites('/api/logs', {
            body: JSON.stringify({ logs }),
            method: 'POST',
            signal: createTimeoutSignal(),
          });

          if (response.status === 201) {
            window.onbeforeunload = null; // Allow the unload to proceed
            window.location.reload(); // Trigger the page unload
          }
        };

        sendLogs();
        return false; // Prevent the unload until logs are sent
      };
    }
  }, []);

  return (
    <>
      <NavBar />
      <div style={classes.root}>
        <LeftDrawer drawerWidth={drawerWidth} />
        <main style={classes.content}>
          <div className="py-5 my-5" style={styles.toolbar} />
          {currentPath && <BreadcrumbsBar link={currentPath} />}
          <div className="mx-5">{children}</div>
        </main>
      </div>
      <AlchemystCopilot />
    </>
  );
}
