import useScreenDimensions from '@/hooks/useScreenDimensions';
import { ScraperQuery, ScrapingSession } from '@/types/augment';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { parseSchema } from '@/utils/scrape';
import { createTimeoutSignal } from '@/utils/signalConfig';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useNavigate } from 'raviger';
import { useEffect, useState } from 'react';
import SchemaBuilderForm from './SchemaBuilderForm';

interface AugmentScraperProps {
  setScrapingStatus: (
    scrapingStatus: 'open' | 'closed' | 'working' | 'saved'
  ) => void;
  scrapingStatus: 'open' | 'closed' | 'working' | 'saved';
  newSession?: boolean;
  scrapingSession?: ScrapingSession;
  setScrapingSession?: React.Dispatch<React.SetStateAction<ScrapingSession>>;
  headless?: boolean;
}

const AugmentScraper = (props: AugmentScraperProps) => {
  const navigate = useNavigate();
  const [scraperQuery, setScraperQuery] = useState<ScraperQuery>({
    pageUrl: '',
    zodSchema: '',
  });
  const windowDimensions = useScreenDimensions();

  const [scraperResult, setScraperResult] = useState<any>(
    props.scrapingSession
  );
  // const [token, setToken] = useState<string | null>(null);

  // const { getToken } = fetchWithRewrites(;

  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  const isHeadless = props.headless ?? false;

  const getScrapingResults = async (
    scraperQuery: ScraperQuery,
    statusUpdateFunction: (status: 'working' | 'open' | 'closed') => any
  ) => {
    statusUpdateFunction('working');
    const res = await fetchWithRewrites('/api/augment/scrape', {
      method: 'POST',
      body: JSON.stringify(scraperQuery),
      signal: createTimeoutSignal(),
    });

    const expectedReturnType = parseSchema(scraperQuery.zodSchema);

    const res_json = await res.json();
    let resp: typeof expectedReturnType | null = null;
    if (res.status === 201) {
      resp = res_json;
    }

    console.log('Input going to the api = ', scraperQuery);
    console.log('Resulting JSON = ', resp);
    statusUpdateFunction('open');
    return resp;
  };

  useEffect(() => {
    props.scrapingStatus === 'saved' &&
      setScraperResult(props.scrapingSession?.data);
    props.scrapingStatus === 'saved' &&
      setScraperQuery(
        props.scrapingSession?.metadata ?? {
          pageUrl: '',
          zodSchema: '',
        }
      );
  }, [props]);

  const handleCloseScraper = () => props.setScrapingStatus('closed');

  const handleSaveRun = async () => {
    const scrapingSession = {
      data: scraperResult,
      metadata: scraperQuery,
    };

    fetchWithRewrites('/api/augment', {
      method: 'POST',
      body: JSON.stringify(scrapingSession),
      signal: createTimeoutSignal(),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log('Received response JSON = ', response);
        console.log('Session saved');
        props.setScrapingStatus('saved');
        navigate(`/augment/${response._id}`);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {props.scrapingStatus !== 'saved' && !isHeadless ? (
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseScraper}
              aria-label="close"
              disabled={props.scrapingStatus === 'working'}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Augmentor Scraper
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSaveRun}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
      ) : (
        <Stack spacing={2} marginY={3}>
          <Typography variant={isHeadless ? 'caption' : 'h6'}>
            Scraping session on {props.scrapingSession?.metadata.pageUrl}
          </Typography>
          <Typography variant="caption">
            Created at {props.scrapingSession?.createdAt ?? 'an unknown time'}
          </Typography>
        </Stack>
      )}
      <Stack padding={5} margin={5} spacing={3}>
        <TextField
          contentEditable={props.scrapingStatus !== 'saved'}
          label="Target Webpage"
          value={scraperQuery.pageUrl}
          onChange={(e) => {
            if (props.scrapingStatus === 'saved') {
              if (!props.setScrapingSession) {
                throw new Error(
                  'If you are using the component for a saved session, you must set the setScrapingSession method.'
                );
              }
              props.setScrapingSession((previousState) => ({
                ...previousState,
                metadata: {
                  ...previousState.metadata,
                  pageUrl: e.target.value,
                },
              }));
            }
            setScraperQuery((prevState) => ({
              ...prevState,
              pageUrl: e.target.value,
            }));
          }}
          InputProps={{
            readOnly: props.scrapingStatus === 'saved',
          }}
        ></TextField>
        <Typography variant={isHeadless ? 'caption' : 'h6'}>
          Define your schema here.
        </Typography>

        <SchemaBuilderForm
          setScraperQuery={setScraperQuery}
          scrapingStatus={props.scrapingStatus}
        />
        <pre>{}</pre>
        {/* <Box marginY={3}>
          <Typography variant="h6">Schema</Typography>
          <pre>
            {JSON.stringify(parseSchema(scraperQuery.zodSchema), null, 2)}
          </pre>
        </Box> */}
        {/* <TextField
          contentEditable={props.scrapingStatus !== "saved"}
          label="Schema"
          multiline
          value={scraperQuery.zodSchema}
          fullWidth
          onChange={(e) => {
            if (props.scrapingStatus === "saved") {
              if (!props.setScrapingSession) {
                throw new Error(
                  "If you are using the component for a saved session, you must set the setScrapingSession method."
                );
              }
              props.setScrapingSession((previousState) => ({
                ...previousState,
                metadata: {
                  ...previousState.metadata,
                  zodSchema: e.target.value,
                },
              }));
            }
            setScraperQuery((prevState) => ({
              ...prevState,
              zodSchema: e.target.value,
            }));
          }}
          InputProps={{
            readOnly: props.scrapingStatus === "saved",
          }}
        ></TextField> */}
        <Box flexDirection={'revert'}>
          <Button
            variant="contained"
            onClick={async () => {
              console.log('Scraper query = ', scraperQuery);
              const res = await getScrapingResults(
                scraperQuery,
                props.setScrapingStatus
              );
              setScraperResult(res);
            }}
            disabled={['working', 'saved'].includes(props.scrapingStatus)}
          >
            Scrape
          </Button>
        </Box>
        <Stack>
          <Box marginY={3}></Box>
          <Typography variant={isHeadless ? 'h6' : 'h3'}>Output</Typography>
          <Box alignSelf={'center'} display={'flex'} flexDirection={'column'}>
            {props.scrapingStatus === 'working' && <CircularProgress />}
            {props.scrapingStatus !== 'working' && (
              <Box
                alignSelf={'center'}
                display={'flex'}
                flexDirection={'column'}
              >
                {scraperResult ? (
                  <DataGridPro
                    sx={{
                      minWidth: windowDimensions.width * 0.5,
                      maxWidth: windowDimensions.width * 0.75,
                    }}
                    checkboxSelection
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    pagination
                    autoHeight
                    rows={
                      Array.isArray(scraperResult)
                        ? scraperResult.map((entry, idx) => {
                            return { id: idx, ...entry };
                          })
                        : [{ ...scraperResult, id: 1 }]
                    }
                    columns={Object.keys(
                      Array.isArray(scraperResult)
                        ? scraperResult[0]
                        : scraperResult
                    ).map((entry) => ({
                      field: entry,
                      headerName: entry,
                    }))}
                    slots={{ toolbar: GridToolbar }}
                  />
                ) : (
                  <Typography variant={isHeadless ? 'caption' : 'body1'}>
                    Nothing to display here
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default AugmentScraper;
