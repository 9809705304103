import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { UpcomingEvent } from '@/types/dashboard/upcomingEvent';
import {
    AccessTime,
    ArrowForward,
    CalendarMonthTwoTone,
    CalendarToday,
    Event,
} from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardContent,
    IconButton,
    Typography,
} from '@mui/material';
import { useNavigate } from 'raviger';
import Moment from 'react-moment';

interface UpcomingEventProps {
  upcomingEvent: UpcomingEvent[];
}

const UpcomingEventCard: React.FC<UpcomingEventProps> = ({ upcomingEvent }) => {
  const hasEvents = upcomingEvent.length > 0;
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: 3,
        margin: 'auto',
        background:
          'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
        borderRadius: 5,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        minWidth: '100%',
      }}
      className={
        currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
      }
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <CalendarMonthTwoTone sx={{ fontSize: 40, opacity: 0.8 }} />
          <Typography variant="h5" component="div">
            Upcoming Events
          </Typography>
        </Box>
        {hasEvents && (
          <Button
            variant="text"
            endIcon={<ArrowForward />}
            sx={{
              color: 'primary.main',
              fontSize: '18px', // Reduced font size for button
              fontWeight: 600,
              textTransform: 'none',
              mt: 2,
            }}
            onClick={() => navigate('/inbox/calendar')}
          >
            See all meetings
          </Button>
        )}
      </Box>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          gap: 2,
        }}
      >
        {hasEvents ? (
          upcomingEvent.map((meet, index) => (
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'transparent',
                my: 1.5,
                paddingX: 1,
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
                ':hover': {
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                  transform: 'scale(1.02)',
                  transition: 'all 0.2s ease-in-out',
                },
                cursor: 'pointer',
                borderRadius: 3,
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5, // Reduced gap between title and date/time
                  flexGrow: 1,
                }}
              >
                <Typography variant="subtitle1" fontWeight="600">
                  {meet.title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Event fontSize="small" />
                    <Typography variant="body2" color="textSecondary">
                      <Moment format="DD/MM/YYYY">{meet.timestamp}</Moment>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <AccessTime fontSize="small" />
                    <Typography variant="body2" color="textSecondary">
                      <Moment format="hh:mm A">{meet.timestamp}</Moment>
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <IconButton sx={{ marginRight: 1.5 }}>
                <ArrowForward fontSize="medium" />
              </IconButton>
            </Card>
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '200px',
              gap: 2,
              color: 'text.secondary',
            }}
          >
            <CalendarToday
              fontSize="large"
              sx={{ fontSize: 60, color: 'gray' }}
            />
            <Typography variant="h6">No upcoming meetings</Typography>
            <Typography variant="body2" color="textSecondary">
              You&apos;re all caught up!
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UpcomingEventCard;
