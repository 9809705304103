import { defaultAlchemysts } from '@/constants';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useScreenDimensions from '@/hooks/useScreenDimensions';
import InfoIcon from '@mui/icons-material/InfoRounded';
import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Link } from 'raviger';

const AlchemystSelection = () => {
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const windowDimensions = useScreenDimensions();
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedAlchemyst = defaultAlchemysts.find(
      (alchemystEntry) => alchemystEntry.persona === event.target.value
    );
    if (selectedAlchemyst) {
      setStoreState({ alchemyst: selectedAlchemyst });
    }
  };

  return (
    <Select
      fullWidth
      defaultValue={'maya'}
      onChange={handleChange}
      variant="standard"
      sx={{
        padding: '0.8rem',
        '&::before, &::after': {
          display: 'none',
        },
      }}
      renderValue={(selected) => (
        <Box
          sx={{
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            paddingX: 2.5,
            paddingY: 0.5,
            width: '7.5vw',
            height: '3.5rem',
          }}
          className={
            currentTheme === 'dark'
              ? 'custom-border'
              : 'custom-border_for_light'
          }
        >
          <Avatar
            src={
              defaultAlchemysts.find((a) => a.persona === selected)?.avatarUrl
            }
            alt={`${selected}-avatar`}
            sx={{
              alignSelf: 'center',
              marginY: 1,
              width: Math.min(
                windowDimensions.height * 0.0425,
                windowDimensions.width * 0.0425
              ),
              height: Math.min(
                windowDimensions.height * 0.0425,
                windowDimensions.width * 0.0425
              ),
            }}
          />
          <Typography>
            {defaultAlchemysts.find((a) => a.persona === selected)?.name}
          </Typography>
        </Box>
      )}
    >
      {defaultAlchemysts.map((alchemyst) => (
        <MenuItem
          key={`alchemyst-${alchemyst.persona}`}
          value={alchemyst.persona}
        >
          <Box
            sx={{
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: 2.5,
              paddingY: 0.5,
              // width: "7.5vw",
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: '5vw',
              }}
            >
              <Avatar
                src={alchemyst.avatarUrl}
                alt={`${alchemyst.persona}-avatar`}
                sx={{
                  alignSelf: 'center',
                  marginY: 1,
                  width: Math.min(
                    windowDimensions.height * 0.0425,
                    windowDimensions.width * 0.0425
                  ),
                  height: Math.min(
                    windowDimensions.height * 0.0425,
                    windowDimensions.width * 0.0425
                  ),
                }}
              />
              <Typography>{alchemyst.name}</Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Link
                href="/instructions"
                target="_blank noreferrer"
                className="flex-1"
              >
                <Button variant="text">
                  <InfoIcon />
                </Button>
              </Link>
            </Box>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default AlchemystSelection;
