import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import CircularProgressWithLabel from './CircularProgressWithLabel';

const MailboxHealth: React.FC = () => {
  const [value, setValue] = useState<number>(0);
  const { getLogs } = useLogging();
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);

  const fetchData = useCallback(async () => {
    try {
      const logs = getLogs();
      const successApproveEmail = logs.filter(
        (log) => log.context === 'inbox:approved-email'
      );
      const failedApproveEmail = logs.filter(
        (log) => log.context === 'inbox:failed-approved-email'
      );

      const _value =
        successApproveEmail.length + failedApproveEmail.length === 0
          ? 100
          : (successApproveEmail.length /
              (successApproveEmail.length + failedApproveEmail.length)) *
            100;
      setValue(_value);
    } catch (error) {
      console.error('Failed to fetch logs:', error);
    }
  }, [getLogs]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to determine the color based on value
  const getColor = (value: number) => {
    if (value >= 80) return '#4caf50'; // Green
    if (value >= 50) return '#ffc107'; // Yellow
    return '#f44336'; // Red
  };

  return (
    <Box
      sx={{
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        padding: 2,
        borderRadius: 4,
        background:
          'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
        position: 'relative',
        overflow: 'hidden',
      }}
      className={
        currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          marginBottom: 2,
          gap: 1,
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Mailbox Health
        </Typography>
        <ArrowForwardIos />
      </Box>
      <Typography
        variant="inherit"
        color={'#ffffff90'}
        component="div"
        gutterBottom
        marginBottom={2}
      >
        Overall Mailbox Health
      </Typography>
      <Box position="relative" width={320} height={350}>
        <Box position="absolute" top={0} left={0}>
          <CircularProgressWithLabel
            value={value}
            size={320}
            thickness={2}
            color={getColor(value)}
          />
        </Box>
        <Box position="absolute" top={20} left={20}>
          <CircularProgressWithLabel
            value={value - 10 > 0 ? value - 10 : 0}
            size={280}
            thickness={3}
            color="#00008B"
          />
        </Box>
        <Box position="absolute" top={40} left={40}>
          <CircularProgressWithLabel
            value={value - 20 > 0 ? value - 20 : 0}
            size={240}
            thickness={3}
            color="#ffc658"
          />
        </Box>
        <Box position="absolute" top={60} left={60}>
          <CircularProgressWithLabel
            value={value - 30 > 0 ? value - 30 : 0}
            size={200}
            thickness={3}
            color="#f50057"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MailboxHealth;
