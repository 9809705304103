import { agendaPlaybookList } from '@/constants/leads';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { HourglassEmptySharp, InfoOutlined } from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

type EmailItem = {
  _id: string;
  emailId: string;
};

const textToHtml = (text: string) => {
  return text.replace(/\n/g, '<br />');
};

const BulkEmail: React.FC<{ selectedLeads: Record<string, any>[] }> = ({
  selectedLeads,
}: {
  selectedLeads: Record<string, any>[];
}) => {
  const [fromEmail, setFromEmail] = useState<string>('');
  const { setStoreState, campaignAgenda, currentSnackbarEntries, narratives } =
    useAlchemystStoreForAi((store) => ({
      setStoreState: store.setStoreState,
      campaignAgenda: store.campaignAgenda,
      currentSnackbarEntries: store.snackbarEntries,
      narratives: store.narratives,
    }));
  const [sendingBulkEmails, setSendingBulkEmails] = useState<boolean>(false);
  const { log } = useLogging();
  const [fetchInboxEmail, setFetchInboxEmails] = useState<{
    data: EmailItem[];
    loading: boolean;
  }>({
    data: [],
    loading: false,
  });

  const handleCreateCampaign = async () => {
    // data: selectedLeads, fromEmail, campaignAgenda
    setSendingBulkEmails(true);
    console.log(`Created Campaign for ${selectedLeads.length} leads.`);

    const generatedBulkEmailsResponse = await fetchWithRewrites(
      '/api/emails/generate/bulk',
      {
        method: 'POST',
        body: JSON.stringify({
          leadsInfo: selectedLeads,
          agenda: campaignAgenda,
          narratives,
        }),
        signal: createTimeoutSignal(),
      }
    );

    if (generatedBulkEmailsResponse.status !== 201) {
      console.error("Couldn't generate emails.");
      setStoreState({
        snackbarEntries: [
          ...currentSnackbarEntries,
          {
            type: 'alert',
            severity: 'error',
            message: "Couldn't generate bulk emails. Please try again :(",
            variant: 'determinate',
            autoHideDuration: 6000,
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      return;
    }

    const responseJson: {
      results: { to: string; body: string; subject: string }[];
    } = await generatedBulkEmailsResponse.json();

    const mailsToSend = responseJson.results;

    // NOTE: Now, we need to send the emails

    Promise.all(
      mailsToSend.map(async (mail) => {
        const mailSendingResponse = await fetchWithRewrites(
          '/api/emails/send',
          {
            method: 'POST',
            body: JSON.stringify({
              sender: `Maya <${fromEmail}>`,
              from: fromEmail,
              to: mail.to,
              html: textToHtml(mail.body),
              text: textToHtml(mail.body),
              subject: mail.subject,
            }),
            signal: createTimeoutSignal(),
          }
        );

        if (!mailSendingResponse.ok) {
          setStoreState({
            snackbarEntries: [
              ...currentSnackbarEntries,
              {
                type: 'alert',
                severity: 'error',
                message: `Couldn't send email to ${mail.to}. Please try again in the next batch after a minute or two :(`,
                variant: 'determinate',
                autoHideDuration: 6000,
                notificationId: crypto.randomUUID(),
              },
            ],
          });
        } else {
          setStoreState({
            snackbarEntries: [
              ...currentSnackbarEntries,
              {
                type: 'alert',
                severity: 'success',
                message: `Successfully sent email to ${mail.to}.`,
                variant: 'determinate',
                autoHideDuration: 6000,
                notificationId: crypto.randomUUID(),
              },
            ],
          });
        }
        return mailSendingResponse.status;
      })
    )
      .then((response) => {
        setSendingBulkEmails(false);
        log(
          {
            message: `${mailsToSend.length} emails sent successfully`,
            severity: 'success',
          },
          'platform/leads.bulk-email.send-email.llm-response'
        );
      })
      .catch((error) => {
        setStoreState({
          snackbarEntries: [
            ...currentSnackbarEntries,
            {
              type: 'alert',
              severity: 'error',
              message: "Couldn't send bulk emails. Please try again :(",
              variant: 'determinate',
              autoHideDuration: 6000,
              notificationId: crypto.randomUUID(),
            },
          ],
        });
        log(
          { message: (error as Error).message, severity: 'error' },
          'platform/leads.bulk-email.send-email.llm-response'
        );
        setSendingBulkEmails(false);
      });
  };

  useEffect(() => {
    const fetchEmails = async () => {
      setFetchInboxEmails((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        const response = await fetchWithRewrites(
          '/api/inbox/list?emailsOnly=true',
          {
            signal: createTimeoutSignal(),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch emails');
        const emails = await response.json();
        if (emails)
          setFetchInboxEmails({
            data: emails.data,
            loading: false,
          });
        setFromEmail(emails.data[0]?.emailId);
        const inboxEmailsList = emails.data.map(
          (item: EmailItem) => item.emailId
        );
        setStoreState({
          inboxEmails: inboxEmailsList,
        });

        console.log(emails.data[0]);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
  }, [setStoreState]);
  if (!selectedLeads || selectedLeads.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '50vh',
          width: '100%',
          gap: 2,
        }}
      >
        <HourglassEmptySharp sx={{ fontSize: 50 }} />
        <Typography variant="h6">
          Select atleast 1 lead to send email
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="from-email">From Email</InputLabel>
        <Select
          value={fromEmail}
          onChange={(e) => setFromEmail(e.target.value)}
          variant="outlined"
          label="From Email"
          labelId="from-email"
          placeholder="Select From Email"
        >
          {fetchInboxEmail?.data.map((email) => (
            <MenuItem
              key={email._id}
              value={email.emailId}
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '100%',
              }}
            >
              {email.emailId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Agenda"
        variant="outlined"
        multiline
        rows={2}
        value={campaignAgenda ?? ''}
        onChange={(e) =>
          setStoreState({
            campaignAgenda: e.target.value,
          })
        }
      />
      <TextField
        label="Narratives"
        variant="outlined"
        multiline
        minRows={2}
        maxRows={6}
        value={(narratives ?? []).join('\n')}
        onChange={(e) =>
          setStoreState({
            narratives: e.target.value.toString().split('\n'),
          })
        }
      />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          marginTop: 2,
        }}
      >
        <InfoOutlined sx={{ color: 'gray', fontSize: 20, mt: 0.2 }} />
        <Typography variant="subtitle2" color={'gray'}>
          This will send a hyper personalized email to each of the{' '}
          {selectedLeads.length} leads.
        </Typography>
      </Box>
      <StyledButton
        variant="contained"
        color="primary"
        fullWidth
        disabled={sendingBulkEmails}
        onClick={handleCreateCampaign}
      >
        Create Sales Campaign
      </StyledButton>
      <Box component="hr" sx={{ width: '100%', my: 2 }} />
      <Box>
        <Typography variant="subtitle2">
          Set your agenda with predefined playbooks
        </Typography>
      </Box>
      <Box sx={{ overflowX: 'auto', display: 'flex', gap: 2, py: 2 }}>
        {agendaPlaybookList.map((agenda, index) => (
          <Box
            key={index}
            sx={{
              minWidth: '200px',
              background: 'inherit',
              padding: 2,
              borderRadius: 1,
              boxShadow: 1,
              cursor: 'pointer',
              '&:hover': {
                boxShadow: 3,
              },
            }}
            onClick={() =>
              setStoreState({ campaignAgenda: agenda.description })
            }
          >
            <Typography variant="body1">{agenda.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BulkEmail;
