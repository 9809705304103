
import { Header, StyledContainer } from '@/constants/themes';
import { AutoEmail } from '@/types/email';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AutoEmails from './AutoEmails';

const AutoEmailLayout = () => {
  const [emails, setEmails] = useState<AutoEmail[]>([]);

  useEffect(() => {
    try {
      const storedEmails = localStorage.getItem('autoEmails');
      if (storedEmails) {
        setEmails(JSON.parse(storedEmails));
      }
    } catch (error) {
      console.error('Failed to retrieve emails from localStorage:', error);
    }
  }, []);

  // Future "Approve All" functionality placeholder:
  // const handleApproveAll = () => {
  //   setEmails([]);
  //   setStoreState({
  //     snackbarEntries: [
  //       ...snackbarEntries,
  //       {
  //         message: "All emails approved",
  //         severity: "success",
  //         type: "alert",
  //       },
  //     ],
  //   });
  // };

  return (
    <StyledContainer maxWidth="xl">
      <Header>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          gap={2}
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              Automated Emails
            </Typography>
            <Typography variant="body1" marginTop={2}>
              Approve or reject automated emails here.
            </Typography>
          </Box>
          {/* Placeholder for "Approve All" button
          <StyledButton
            variant="contained"
            component="label"
            startIcon={<AddTask />}
            sx={{
              padding: "8px 26px",
              fontSize: "16px",
              borderRadius: "8px",
            }}
            onClick={handleApproveAll}
          >
            Approve All
          </StyledButton> */}
        </Box>
      </Header>
      <AutoEmails emails={emails} setEmails={setEmails} />
    </StyledContainer>
  );
};

export default AutoEmailLayout;
