import { ParsedJSON, SearchParamsObject } from '@/types/prospector/openSearch';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { SearchResult } from '@/utils/search/openSearch';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { GridColDef } from '@mui/x-data-grid-pro';

export function parseStringToJSON(input: string): ParsedJSON {
  const parsed = JSON.parse(input);

  return parsed;
}

export const unslugifyField = (field: string) => {
  let unslugifiedName = field.replaceAll('_', ' ');
  return unslugifiedName.charAt(0).toUpperCase() + unslugifiedName.slice(1);
};

export const fetchFromRecommender = async (
  content: string
): Promise<{
  results: SearchResult | null;
  query: SearchParamsObject | null;
}> => {
  try {
    const response = await fetchWithRewrites('/api/maya/prospector/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chat_history: [
          {
            content: { content },
            type: 'user',
          },
        ],
      }),
      signal: createTimeoutSignal(),
    });

    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    return { results: null, query: null };
  }
};

export const flattenForRender = (
  searchEntry: object
): Record<string, string | number> => {
  let flattenedEntry: Record<string, string | number> = {};
  for (const [key, value] of Object.entries(searchEntry)) {
    if (typeof value === 'object') {
      flattenedEntry[key] = JSON.stringify(value);
    } else if (!value || value === null) {
      flattenedEntry[key] = 'NA';
    } else {
      flattenedEntry[key] = value;
    }
  }

  return flattenedEntry;
};

export const generateColumns = (data: any): GridColDef[] => {
  return Object.keys(data).map((key) => ({
    field: key,
    headerName: key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase()),
    width: 150,
  }));
};
