export const tunerOptions = [
  {
    name: 'Prospector',
    keyword: '\nuse prospector.',
  },
  {
    name: 'Web Search',
    keyword: '\nuse web search.',
  },
];
