import { Header, StyledButton, StyledContainer } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import fetchThreadIdList from '@/utils/inbox/fetchThreadIdList';
import useEmailFetcher from '@/utils/inbox/useEmailFetcher';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { CalendarMonthOutlined, Refresh } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useNavigate } from 'raviger';
import { FC, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import AddEmailModal from './AddEmailModal';
import ImapModal from './ImapModal';
import InboxMenu from './InboxMenu';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import EmailConfigModal from './components/EmailConfigModal/EmailConfigModal';

const ModalTypes = z.enum(['addEmailModal', 'inboxConfigModal', 'imapModal']);

type EmailItem = {
  _id: string;
  emailId: string;
};
const InboxLayout: FC = () => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState<
    Record<z.infer<typeof ModalTypes>, boolean>
  >({
    addEmailModal: false,
    inboxConfigModal: false,
    imapModal: false,
  });
  const [inboxAuthenticated, setInboxAuthenticated] = useState<boolean>(false);
  const [isCredentialsSet, setIsCredentialsSet] = useState<boolean>(true);

  const {
    threadIds,
    emailsToCheck,
    progressLoading,
    preparingThreads,
    inboxEmails,
  } = useAlchemystStoreForAi((store) => ({
    threadIds: store.threadIds,
    emailsToCheck: store.emailsToCheck,
    progressLoading: store.progressLoading,
    preparingThreads: store.preparingThreadLoading,
    inboxEmails: store.inboxEmails,
  }));

  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);

  const { done, fetchEmailBodies, deleteEmailBodies } = useEmailFetcher();

  // const { getToken } = useAuth();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const loadEmailsFromLocalStorage = async () => {
    if (!inboxAuthenticated) return;
    setStoreState({ preparingThreadLoading: true });
    const storedEmails = localStorage.getItem('emailsToCheck');
    const emailsToCheck = storedEmails ? JSON.parse(storedEmails) : [];

    const storedThreadIds = localStorage.getItem('threadIds');
    const threadIds = storedThreadIds ? JSON.parse(storedThreadIds) : [];

    if (threadIds.length > 0) {
      setStoreState({ threadIds });
    } else {
      const fetchedThreadIds = await fetchThreadIdList(emailsToCheck);
      setStoreState({ threadIds: fetchedThreadIds });
      localStorage.setItem('threadIds', JSON.stringify(fetchedThreadIds));
    }

    setStoreState({ emailsToCheck });
    fetchEmailBodies(emailsToCheck, threadIds);
  };

  const autoLoginToInbox = async () => {
    try {
      const response = await fetchWithRewrites('/api/inbox/auth', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        signal: createTimeoutSignal(),
      });

      const credentialsData = await response.json();

      // case for no inboxes present
      if (credentialsData.data && credentialsData.data.length === 0) {
        setModalOpen((prev) => ({
          ...prev,
          imapModal: true,
        }));
      }

      if (credentialsData.response !== 'ok')
        throw new Error(`Error: ${response.statusText}`);

      if (credentialsData.isAuthenticated) {
        // ("Authenticated");
        setInboxAuthenticated(true);
        setIsCredentialsSet(true);
      } else {
        setIsCredentialsSet(false);
      }
    } catch (error) {
      console.error('Failed to auto-login:', error);
      setIsCredentialsSet(false);
    }
  };

  const handleEmailUpdate = async (
    newEmails: string[],
    deletedEmails: string[]
  ) => {
    const updatedEmailsToCheck = [
      ...new Set([...emailsToCheck, ...newEmails]),
    ].filter((email) => !deletedEmails.includes(email));
    setStoreState({ emailsToCheck: updatedEmailsToCheck });
    localStorage.setItem('emailsToCheck', JSON.stringify(updatedEmailsToCheck));

    const newThreadIds =
      newEmails.length > 0 ? await fetchThreadIdList(newEmails) : [];
    const deletedThreadIds =
      deletedEmails.length > 0 ? await fetchThreadIdList(deletedEmails) : [];

    const updatedThreadIds = [
      ...new Set(
        [...threadIds, ...newThreadIds].filter(
          (threadId) => !deletedThreadIds.includes(threadId)
        )
      ),
    ];

    setStoreState({ threadIds: updatedThreadIds });
    localStorage.setItem('threadIds', JSON.stringify(updatedThreadIds));

    if (newEmails.length > 0 && newThreadIds.length > 0) {
      fetchEmailBodies(newEmails, newThreadIds, true);
    }

    if (deletedEmails.length > 0) deleteEmailBodies(deletedEmails);
  };

  const handleRefresh = () => {
    setStoreState({
      preparingThreadLoading: true,
      progressLoading: 0,
      emailBodiesByThreadByEmail: {},
    });
    loadEmailsFromLocalStorage();
  };

  useEffect(() => {
    const fetchUserInbox = async () => {
      const response = await fetchWithRewrites(
        '/api/inbox/list?emailsOnly=true',
        {
          signal: createTimeoutSignal(),
        }
      );
      if (!response.ok) throw new Error('Failed to fetch emails');
      const emails = await response.json();
      // console.log(emails, 'emaisl');
      const inboxEmailsList = emails.data.map(
        (item: EmailItem) => item.emailId
      );
      if (inboxEmailsList)
        setStoreState({
          inboxEmails: inboxEmailsList,
        });
    };

    fetchUserInbox();
  }, [setStoreState]);

  useEffect(() => {
    if (!inboxAuthenticated) {
      autoLoginToInbox();
    }

    if (
      (inboxAuthenticated && emailsToCheck.length === 0) ||
      threadIds.length === 0
    ) {
      loadEmailsFromLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxAuthenticated]);

  const Loading: FC<{ message: string }> = ({ message }) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        height: '60vh',
      }}
    >
      <CircularProgress />
      <Typography variant="h6">{message}</Typography>
    </Box>
  );

  const LoadingMenu = () => {
    if (emailsToCheck.length === 0 || threadIds.length === 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
            height: '60vh',
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No emails added to check.
          </Typography>
        </Box>
      );
    }

    if (!isCredentialsSet) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
            height: '60vh',
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No IMAP credentials set.
          </Typography>
          <StyledButton
            variant="contained"
            onClick={() =>
              setModalOpen((prevState) => ({ ...prevState, imapModal: true }))
            }
          >
            Set IMAP Credentials
          </StyledButton>
        </Box>
      );
    }

    if (!inboxAuthenticated) {
      return <Loading message="Hold on! Authenticating..." />;
    }

    if (preparingThreads) {
      return <Loading message="Preparing emails..." />;
    }
  };

  return (
    <StyledContainer maxWidth="xl">
      <Header>
        <Box>
          <Typography variant="h4" gutterBottom>
            Inbox
          </Typography>
          <Typography variant="body1">Manage your mailbox here.</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <StyledButton
            variant="contained"
            startIcon={<Refresh />}
            sx={{
              padding: '8px 26px',
              fontSize: '16px',
              borderRadius: '8px',
            }}
            disabled={!inboxAuthenticated}
            onClick={handleRefresh}
          >
            Refresh
          </StyledButton>
          <StyledButton
            variant="contained"
            startIcon={<CalendarMonthOutlined />}
            sx={{
              padding: '8px 26px',
              fontSize: '16px',
              borderRadius: '8px',
            }}
            onClick={() => navigate('/inbox/calendar')}
            disabled={!inboxAuthenticated}
          >
            Calendar
          </StyledButton>
          <StyledButton
            aria-controls={isMenuOpen ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleMenuClick}
            // disabled={!inboxAuthenticated}
            variant="text"
            sx={{
              fontSize: '16px',
              borderRadius: '8px',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <MoreVertIcon />
          </StyledButton>
          <Menu
            id="menu"
            anchorEl={menuAnchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() =>
                setModalOpen((prev) => ({
                  ...prev,
                  addEmailModal: true,
                }))
              }
            >
              Add Email
            </MenuItem>
            <MenuItem
              onClick={() =>
                setModalOpen((prev) => ({
                  ...prev,
                  imapModal: true,
                }))
              }
            >
              Add Inbox
            </MenuItem>
            <MenuItem
              onClick={() =>
                setModalOpen((prev) => ({
                  ...prev,
                  inboxConfigModal: true,
                }))
              }
            >
              Inbox Config
            </MenuItem>
          </Menu>
        </Box>
      </Header>

      <LoadingMenu />

      {!done && isCredentialsSet && inboxAuthenticated && !preparingThreads && (
        <Box width="100%">
          <LinearProgressWithLabel value={progressLoading} />
        </Box>
      )}
      {isCredentialsSet && inboxAuthenticated && !preparingThreads && (
        <InboxMenu />
      )}

      {modalOpen.inboxConfigModal && (
        <EmailConfigModal
          inboxConfigModalVisible={modalOpen.inboxConfigModal}
          setModalOpen={setModalOpen}
        />
      )}

      <AddEmailModal
        open={modalOpen.addEmailModal}
        onClose={() => {
          setModalOpen((prev) => ({
            ...prev,
            addEmailModal: false,
          }));
        }}
        handleAddEmail={handleEmailUpdate}
      />

      <ImapModal
        open={modalOpen.imapModal}
        onClose={(event?: {}, reason?: 'escapeKeyDown' | 'backdropClick') => {
          if (reason === 'backdropClick' && inboxEmails.length === 0) return;
          setModalOpen((prev) => ({
            ...prev,
            imapModal: false,
          }));
        }}
        setIsCredentialsSet={setIsCredentialsSet}
        setInboxAuthenticated={setInboxAuthenticated}
      />
    </StyledContainer>
  );
};

export default InboxLayout;
