import { fetchWithRewrites } from './fetchWithRewrites';
import { createTimeoutSignal } from './signalConfig';

/**
 * Sets IMAP credentials by making a POST request to the API.
 *
 * @param {string} imapEmail - The IMAP email address.
 * @param {string} imapPassword - The IMAP password.
 * @param {string} meetingLink - The meeting link.
 * @returns {Promise<boolean>} - Returns true if credentials were set successfully, false otherwise.
 */
export const setImapCredentials = async (
  imapEmail: string,
  imapPassword: string,
  meetingLink: string
): Promise<boolean> => {
  try {
    const formatImapPassword = imapPassword.replace(/\s+/g, '');
    const response = await fetchWithRewrites('/api/inbox/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: imapEmail,
        password: formatImapPassword,
        meetingLink: meetingLink,
      }),
      signal: createTimeoutSignal(),
    });

    if (!response.ok) {
      console.error(`Error: ${response.statusText}`);
      return false;
    }

    return response.status === 201;
  } catch (error) {
    console.error('Failed to set IMAP credentials:', error);
    return false;
  }
};
