import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CircularProgress, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { Dispatch, SetStateAction, useState } from 'react';

import {
    ModalContent,
    ModalHeader,
    StyledButton,
    StyledModal,
} from '@/constants/themes';
import { AppContextStateForAi } from '@/types/appStates';
import { Close, SystemUpdateAlt } from '@mui/icons-material';
import { useNavigate } from 'raviger';
import LeadsTableView from './LeadsTableView';

interface Lead extends Record<string, any> {
  id?: number;
}

type Props = {
  localLeads: Lead[];
  localFields: AppContextStateForAi['fields'];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const LeadsImportModal = ({
  localLeads,
  localFields,
  open,
  setOpen,
}: Props) => {
  const navigate = useNavigate();
  const leads = useAlchemystStoreForAi((store) => store.leads);
  const fields = useAlchemystStoreForAi((store) => store.fields);
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const [isImporting, setIsImporting] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
  };

  const onImportData = () => {
    setIsImporting(true);
    const localLeadsWithIds = localLeads.map((lead, index) => ({
      ...lead,
      id: leads.length + index,
    }));
    setStoreState({
      leads: [...leads, ...localLeadsWithIds],
      fields: [...fields, ...localFields],
    });
    setTimeout(() => {
      setIsImporting(false);
      onClose();
      navigate('/leads');
    }, 1000);
  };

  return (
    <StyledModal open={open} onClose={onClose}>
      <ModalContent
        sx={{
          maxWidth: '65vw',
          maxHeight: '70vh',
          backgroundColor: 'background.paper',
        }}
      >
        <ModalHeader>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={onImportData}
            startIcon={
              isImporting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <SystemUpdateAlt />
              )
            }
          >
            Import Data to Leads
          </StyledButton>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </ModalHeader>
        <Box sx={{ height: '50vh', width: '100%' }}>
          <LeadsTableView
            leads={localLeads}
            fields={localFields}
            hideFooter={false}
            showSelection={false}
          />
        </Box>
      </ModalContent>
    </StyledModal>
  );
};

export default LeadsImportModal;
