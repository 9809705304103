
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { Log } from '@/types/logging';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import {
    Box,
    CircularProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

const AnalyticsCard: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<
    'Past 30 Days' | 'Past 60 Days' | 'Past 90 Days'
  >('Past 30 Days');
  const [emailReceivedLog, setEmailReceivedLog] = useState<Log[]>([]);
  const [emailSentLog, setEmailSentLog] = useState<Log[]>([]);
  const [leadsGeneratedLog, setLeadsGeneratedLog] = useState<Log[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<
    Array<{
      date: string;
      'email received': number;
      'email sent': number;
      'leads generated': number;
    }>
  >([]);

  const currentTheme = useAlchemystStoreForAi((store) => store.theme);

  const { getLogs } = useLogging();

  const fetchData = useCallback(() => {
    const logs = getLogs();
    try {
      setEmailReceivedLog(
        logs.filter((log) => log.context === 'inbox:received-email')
      );
      setEmailSentLog(logs.filter((log) => log.context === 'inbox:sent-email'));
      setLeadsGeneratedLog(
        logs.filter((log) => log.context === 'leads:generated')
      );
    } catch (error) {
      console.error('Failed to fetch logs:', error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filterLogs = useCallback(
    (days: number) => {
      const thresholdDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
      return {
        emailReceived: emailReceivedLog.filter(
          (log) => new Date(log.timestamp) > thresholdDate
        ),
        emailSent: emailSentLog.filter(
          (log) => new Date(log.timestamp) > thresholdDate
        ),
        leadsGenerated: leadsGeneratedLog.filter(
          (log) => new Date(log.timestamp) > thresholdDate
        ),
      };
    },
    [emailReceivedLog, emailSentLog, leadsGeneratedLog]
  );

  const generateData = useCallback(
    (days: number) => {
      const { emailReceived, emailSent, leadsGenerated } = filterLogs(days);
      const result: Array<{
        date: string;
        'email received': number;
        'email sent': number;
        'leads generated': number;
      }> = [];

      for (let i = 0; i < days; i++) {
        const date = new Date(
          Date.now() - i * 24 * 60 * 60 * 1000
        ).toDateString();
        result.push({
          date,
          'email received': emailReceived.filter(
            (log) => new Date(log.timestamp).toDateString() === date
          ).length,
          'email sent': emailSent.filter(
            (log) => new Date(log.timestamp).toDateString() === date
          ).length,
          'leads generated': leadsGenerated.filter(
            (log) => new Date(log.timestamp).toDateString() === date
          ).length,
        });
      }
      return result;
    },
    [filterLogs]
  );

  useEffect(() => {
    const daysMapping: { [key: string]: number } = {
      'Past 30 Days': 30,
      'Past 60 Days': 60,
      'Past 90 Days': 90,
    };
    setData(generateData(daysMapping[selectedOption]));
  }, [generateData, selectedOption]);

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(
      event.target.value as 'Past 30 Days' | 'Past 60 Days' | 'Past 90 Days'
    );
  };

  return (
    <Box
      width={'70%'}
      sx={{
        gap: 1,
        paddingX: 2,
        paddingY: 2,
        borderRadius: 6,
        background:
          currentTheme === 'dark'
            ? 'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)'
            : 'linear-gradient(268.4deg, rgba(255, 160, 91, 0.1) 1.52%, rgba(31, 156, 154, 0.1) 98.82%)',
        cursor: 'pointer',
      }}
      className={
        currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 3,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography variant="h5" component="div" gutterBottom>
            Analytics
          </Typography>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            variant="outlined"
            size="small"
            sx={{
              borderRadius: 4,
              paddingX: 1,
            }}
            aria-label="Select time range"
          >
            <MenuItem value="Past 30 Days">Past 30 Days</MenuItem>
            <MenuItem value="Past 60 Days">Past 60 Days</MenuItem>
            <MenuItem value="Past 90 Days">Past 90 Days</MenuItem>
          </Select>
        </Box>
        <ArrowForwardIos />
      </Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={400}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#ffffff50" />
            <XAxis dataKey="date" stroke="#ffffff50" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d820" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d80" />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="email received"
              stroke="#ff9a35"
              activeDot={{ r: 8 }}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="email sent"
              stroke="#128F8B"
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="leads generated"
              stroke="red"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default AnalyticsCard;
