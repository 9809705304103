import { ScraperQuery } from '@/types/augment';
import { Add, Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ZodSchema, z } from 'zod';

type FieldType = 'string' | 'number';

interface SchemaField {
  key: string;
  type: FieldType;
  isArray: boolean;
}

type SchemaBuilderFormProps = {
  setScraperQuery: Dispatch<SetStateAction<ScraperQuery>>;
  scrapingStatus: 'open' | 'closed' | 'working' | 'saved';
  headless?: boolean;
};

const SchemaBuilderForm: React.FC<SchemaBuilderFormProps> = ({
  setScraperQuery,
  scrapingStatus,
  headless,
}) => {
  const isHeadless = headless ?? false;
  const [fields, setFields] = useState<SchemaField[]>([]);

  const handleAddField = () => {
    setFields([...fields, { key: '', type: 'string', isArray: false }]);
  };

  const handleRemoveField = (index: number) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleFieldChange = (
    index: number,
    key: keyof SchemaField,
    value: any
  ) => {
    const newFields = fields.map((field, i) =>
      i === index ? { ...field, [key]: value } : field
    );
    setFields(newFields);
  };

  const isLastFieldIncomplete = () => {
    if (fields.length === 0) return false;
    const lastField = fields[fields.length - 1];
    return !lastField.key || !lastField.type;
  };

  useEffect(() => {
    const generateZodSchema = () => {
      const schema: { [key: string]: ZodSchema } = {};
      fields.forEach((field) => {
        if (field.type === 'string') {
          schema[field.key] = field.isArray ? z.array(z.string()) : z.string();
        } else if (field.type === 'number') {
          schema[field.key] = field.isArray ? z.array(z.number()) : z.number();
        }
      });
      const zodSchema = z.object(schema);
      console.log(zodSchema.toString());
      setScraperQuery((prev) => ({ ...prev, zodSchema: zodSchema.toString() }));
    };

    generateZodSchema();
  }, [fields, setScraperQuery]);

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      {fields.map((field, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          mb={2}
          flexDirection={isHeadless ? 'column' : 'row'}
        >
          <TextField
            label="Field Key"
            variant="outlined"
            value={field.key}
            onChange={(e) => handleFieldChange(index, 'key', e.target.value)}
            sx={{ marginRight: 2, width: '100%' }}
          />
          <TextField
            select
            label="Type"
            variant="outlined"
            value={field.type}
            onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
            sx={{ marginRight: 2, width: 150 }}
          >
            <MenuItem value="string">String</MenuItem>
            <MenuItem value="number">Number</MenuItem>
          </TextField>
          <FormControlLabel
            control={
              <Switch
                checked={field.isArray}
                onChange={(e) =>
                  handleFieldChange(index, 'isArray', e.target.checked)
                }
                color="primary"
              />
            }
            label="Multiple Item"
            sx={{ marginRight: 2 }}
          />
          <IconButton onClick={() => handleRemoveField(index)} color="error">
            <Remove
              fontSize="medium"
              sx={{
                color: 'red',
                backgroundColor: '#ff573340',
                borderRadius: '50%',
              }}
            />
          </IconButton>
        </Box>
      ))}
      <Button
        fullWidth={isHeadless}
        variant="contained"
        onClick={handleAddField}
        startIcon={<Add />}
        disabled={
          isLastFieldIncomplete() ||
          scrapingStatus === 'working' ||
          scrapingStatus === 'saved'
        }
      >
        Add Field
      </Button>
    </Paper>
  );
};

export default SchemaBuilderForm;
