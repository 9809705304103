/* eslint-disable react-hooks/rules-of-hooks */
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { ChatHistoryContainerProps } from '@/types/components/converse/history';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChatHistoryList from './ChatHistoryList';
import ChatHistorySkeleton from './ChatHistorySkeleton';

/**
 * ChatHistoryContainer component
 *
 * This component displays the chat history and a "New Chat" button to start a new chat session.
 * If loading, it shows a skeleton loader to indicate data is being fetched.
 * Otherwise, it renders the chat history list and the button to initiate a new chat.
 *
 * @param loading - Boolean to determine if the skeleton loader should display
 * @param setCurrentChatID - Callback to set the current chat ID; used to start a new or existing chat
 * @param props - Additional properties passed down to the ChatHistoryList component
 */

const ChatHistoryContainer: React.FC<ChatHistoryContainerProps> = ({
  loading,
  setCurrentChatID,
  ...props
}) => {
  if (loading) {
    return <ChatHistorySkeleton skeletonCount={10} />;
  }
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  return (
    <Box sx={{ paddingX: 2 }}>
      <Typography
        variant="h5"
        sx={{
          marginBottom: 3,
        }}
      >
        Chat History
      </Typography>

      <Stack spacing={1}>
        <StyledButton
          variant="outlined"
          color="primary"
          onClick={() => {
            setCurrentChatID('');
            setStoreState({ isResearchMode: false });
          }}
          startIcon={<Add />}
          sx={{
            borderRadius: 1,
            color: '#ff9a35',
            borderColor: '#ff9a3560',
            '&:hover': {
              borderColor: '#ff9a35',
              backgroundColor: '#ff9a3510',
            },
          }}
        >
          New Chat
        </StyledButton>

        <ChatHistoryList setCurrentChatID={setCurrentChatID} {...props} />
      </Stack>
    </Box>
  );
};

export default ChatHistoryContainer;
