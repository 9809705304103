/**
 * Utility for configuring a timeout signal using AbortController.
 *
 * @param timeout - Timeout duration in milliseconds
 * @returns An AbortSignal with timeout
 */

const FETCH_TIMEOUT = 300_000;

export const createTimeoutSignal = (): AbortSignal => {
  // const controller = new AbortController();
  // const signal = AbortSignal.timeout(FETCH_TIMEOUT);

  // // Ensure the signal aborts the controller
  // signal.addEventListener('abort', () => controller.abort());

  // return controller.signal;
  return AbortSignal.timeout(FETCH_TIMEOUT);
};
