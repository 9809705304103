import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface Instruction {
  success: boolean;
  instructions: string;
}

function InstructionsComponent() {
  const currentAlchemyst = useAlchemystStoreForAi((store) => store.alchemyst);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const [instructions, setInstructions] = useState<string>('');

  const getInstruction = async (persona: string): Promise<Instruction> => {
    const res = await fetchWithRewrites(
      `/api/instructions?persona=${persona}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        signal: createTimeoutSignal(),
      }
    );

    const res_json = await res.json();
    console.log('Instructions = ', res_json);
    return res_json;
  };

  const postInstruction = async (
    persona: string,
    instructions: string
  ): Promise<Instruction> => {
    console.log('Sending instructions to the server.');
    const res = await fetchWithRewrites(`/api/instructions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        personality: persona,
        client_id: '1',
        instructions: instructions,
      }),
      signal: createTimeoutSignal(),
    });

    const res_json: {
      success: boolean;
      message: string;
      status: number;
    } = await res.json();

    if (!res_json.success) {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: res_json.success ? 'error' : 'success',
            type: 'alert',
            message: res_json.message,
          },
        ],
      });
    }
    return {
      instructions: res_json.message,
      success: res_json.success,
    };
  };

  useEffect(() => {
    getInstruction(currentAlchemyst.persona).then((data) => {
      setInstructions(data.instructions);
    });
  }, []);

  useEffect(() => {
    getInstruction(currentAlchemyst.persona).then((data) => {
      setInstructions(data.instructions);
    });
  }, [currentAlchemyst]);

  const handleInstructionsUpdate = async () => {
    await postInstruction(currentAlchemyst.persona, instructions);
    getInstruction(currentAlchemyst.persona).then((data) => {
      setInstructions(data.instructions);
    });
  };
  return (
    <Stack spacing={2}>
      <Typography variant="body2">
        Fine tune {currentAlchemyst.name} according to your organization&apos;s
        needs, through text!
      </Typography>
      <TextField
        autoFocus
        id="outlined-multiline-static"
        label="Instructions"
        multiline
        rows={10}
        defaultValue={
          (instructions ?? '').startsWith('"')
            ? JSON.parse(instructions)
            : instructions
        }
        onChange={(e) => setInstructions(e.target.value)}
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleInstructionsUpdate}
        disabled={instructions?.length === 0}
      >
        Update
      </Button>
    </Stack>
  );
}

export default InstructionsComponent;
