import {
  footerText,
  leftContentWrapper,
  rightImage,
  signInContainer,
  styledCard,
} from '@/constants/themes/index'; // Adjust the path as needed
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import GoogleIcon from '@mui/icons-material/Google';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

export default function SignInPage() {
  return (
    <Grid container sx={signInContainer}>
      <Grid item xs={12} md={6} sx={leftContentWrapper}>
        <Card sx={styledCard}>
          <CardContent>
            <Stack direction="column" spacing={2} alignItems="center">
              <img
                src="https://alchemyst-updated.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FAI.e605ee6d.png&w=1080&q=75"
                alt="Logo"
                width={300}
                height={100}
              />
              <Typography variant="h5" align="center" sx={{ mt: -2 }}>
                Welcome to the future of workforce
              </Typography>
              <Typography variant="body1" color={'gray'} align="center">
                Sign in to access your account and enjoy all the features.
              </Typography>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="warning"
              startIcon={<GoogleIcon />}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/google`;
              }}
              sx={{
                textTransform: 'none',
                padding: '10px 20px',
                width: '100%',
              }}
            >
              Sign in with Google
            </Button>
          </CardActions>
          <Typography
            variant="caption"
            sx={footerText}
            align="center"
            justifyContent={'center'}
            display={'flex'}
          >
            © {new Date().getFullYear()} Alchemyst.AI. All rights reserved.
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={rightImage}>
          <img
            src="/ai-bg.jpeg"
            alt="AI Image"
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
