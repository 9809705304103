import { fetchWithRewrites } from '../fetchWithRewrites';
import { createTimeoutSignal } from '../signalConfig';

const fetchThreadIdList = async (emailToCheck: string[]): Promise<string[]> => {
  try {
    const response = await fetchWithRewrites('/api/inbox', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailsToCheck: emailToCheck,
      }),
      signal: createTimeoutSignal(),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const threadIdData = await response.json();
    return threadIdData.threads;
  } catch (error) {
    console.error('Failed to fetch thread IDs:', error);
    return [];
  }
};

export default fetchThreadIdList;
