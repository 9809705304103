import { ModalContent, StyledModal } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { AutoEmail } from '@/types/email';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import EmailCard from '../common/emails/EmailCard';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';

const getEmailAddress = async (email: AutoEmail): Promise<string> => {
  return email.from;
};

const textToHtml = (text: string) => {
  return text.replace(/\n/g, '<br />');
};

const ChatModal = ({
  emailContent,
  open: visible,
  setOpen,
}: {
  emailContent:
    | string
    | (Partial<AutoEmail> & Pick<AutoEmail, 'body' | 'subject'>);
  open: boolean;
  setOpen: (status: boolean) => void;
}) => {
  const [fullEmail, setFullEmail] = useState<AutoEmail | null>(null);

  useEffect(() => {
    setFullEmail({
      from: '',
      to: '',
      date: new Date().toISOString(),
      approved: false,
      ...(typeof emailContent === 'string'
        ? { body: emailContent, subject: '' }
        : emailContent),
    });
  }, [emailContent]);

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const { addLogs } = useLogging();
  const theme = useTheme();

  const handleApprove = async (approvedEmail: AutoEmail) => {
    setLoadingStatus(true);
    const senderEmail = await getEmailAddress(approvedEmail);
    const email = {
      sender: senderEmail,
      from: senderEmail,
      to: approvedEmail.to,
      subject: approvedEmail.subject,
      html: textToHtml(approvedEmail.body),
      text: approvedEmail.body,
    };

    try {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Sending email to ${approvedEmail.to}`,
            severity: 'info',
            type: 'alert',
            autoHideDuration: 5000,
          },
        ],
      });

      const response = await fetchWithRewrites('/api/emails/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(email),
        signal: createTimeoutSignal(),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      addLogs([
        {
          context: 'inbox:approved-email',
          message: `Approved email to ${approvedEmail.to}`,
          metadata: { email },
          timestamp: Date.now(),
        },
      ]);
      setOpen(false);
    } catch (error) {
      console.error('Failed to send email:', error);
      addLogs([
        {
          context: 'inbox:failed-approved-email',
          message: `Failed to send email to ${approvedEmail.to}`,
          metadata: { email },
          timestamp: Date.now(),
        },
      ]);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Failed to send email to ${approvedEmail.to}`,
            severity: 'error',
            type: 'alert',
            autoHideDuration: 5000,
          },
        ],
      });
    } finally {
      setLoadingStatus(false);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Email to ${approvedEmail.to} approved`,
            severity: 'success',
            type: 'alert',
            autoHideDuration: 5000,
          },
        ],
      });
    }
  };

  const handleReject = () => {
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          message: `Email to ${fullEmail?.to} rejected`,
          severity: 'error',
          type: 'alert',
          autoHideDuration: 5000,
        },
      ],
    });
    setOpen(false);
  };

  return (
    <StyledModal
      open={visible}
      onClose={() => setOpen(false)}
      aria-labelledby="chat-modal"
      disableScrollLock
    >
      <ModalContent
        sx={{
          backgroundColor: theme.palette.background.paper,
          maxWidth: '60vw',
        }}
      >
        {fullEmail && fullEmail.body.length > 0 && (
          <EmailCard
            email={fullEmail}
            handleEmailChange={(email) =>
              setFullEmail((prevState) =>
                prevState ? { ...prevState, ...email } : null
              )
            }
            handleApprove={handleApprove}
            handleReject={handleReject}
            maxRows={20}
            fullWidth={true}
          />
        )}
      </ModalContent>
    </StyledModal>
  );
};

export default ChatModal;
