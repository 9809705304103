

import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'raviger';

type EmailPendingCardProps = {
  emailsInQueue: number;
};

const EmailPendingCard: React.FC<EmailPendingCardProps> = ({
  emailsInQueue,
}) => {
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        paddingX: 2,
        paddingY: 2,
        boxSizing: 'border-box',
        borderRadius: 6,
        background:
          'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
        cursor: 'pointer',
      }}
      onClick={() => navigate('/automated-emails')}
      className={
        currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <ForwardToInboxIcon color="primary" />
        <Typography variant="body1">Emails Pending Approval</Typography>
        <Typography variant="body1" color={'primary'}>
          {emailsInQueue}
        </Typography>
      </Box>
      <ArrowForwardIosIcon color="primary" />
    </Box>
  );
};

export default EmailPendingCard;
