// Extract accessToken from cookies
export const getAccessTokenFromCookies = () => {
  const match = document.cookie.match(/(^| )accessToken=([^;]+)/);
  return match && match[2];
};

export const fetchWithRewrites = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  withToken: boolean = true
): Promise<Response> => {
  // const navigate = useNavigate();

  const backendUrl =
    process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3001'; // Default to local server during development.

  const rewrittenUrl =
    typeof input === 'string' && input.startsWith('/api/')
      ? `${backendUrl}${input}`
      : input;

  const accessToken = getAccessTokenFromCookies();

  const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: withToken && accessToken ? `Bearer ${accessToken}` : '',
  };

  try {
    const response = await fetch(rewrittenUrl, {
      ...init,
      headers: {
        ...defaultHeaders,
        ...(init?.headers || {}),
      },
      credentials: init?.credentials ?? 'include', // Ensure cookies are sent
    });

    if (response.status === 401) {
      window.location.href = '/auth';
    }

    return response;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};
