import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { Email } from '@/types/email';
import { HourglassEmpty } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import LeftEmailThreadBar from './LeftEmailThreadBar';
import RightEmailThread from './RightEmailThread';

const InboxMenu: FC = () => {
  const [selectedEmails, setSelectedEmails] = useState<Email[]>([]);
  const [senderEmail, setSenderEmail] = useState<string>('');

  const { emailBodiesByThreadByEmail, selectedThread, setStoreState } =
    useAlchemystStoreForAi((store) => {
      return {
        emailBodiesByThreadByEmail: store.emailBodiesByThreadByEmail,
        selectedThread: store.selectedThread,
        setStoreState: store.setStoreState,
      };
    });

  useEffect(() => {
    if (selectedThread) {
      const threadEmails = Object.values(emailBodiesByThreadByEmail)
        .flat()
        .find((item) => Object.keys(item)[0] === selectedThread);
      if (threadEmails) setSelectedEmails(threadEmails[selectedThread]);
      Object.keys(emailBodiesByThreadByEmail).forEach((emailId) => {
        emailBodiesByThreadByEmail[emailId].forEach((email) => {
          if (Object.keys(email)[0] === selectedThread) {
            setSenderEmail(emailId);
            return;
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThread]);

  if (!Object.keys(emailBodiesByThreadByEmail).length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,
          height: '60vh',
        }}
      >
        <HourglassEmpty sx={{ fontSize: 50 }} />
        <Typography variant="h5" color="textSecondary">
          No emails to show.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        overflowY: 'auto',
      }}
    >
      <LeftEmailThreadBar />
      <RightEmailThread
        selectedEmails={selectedEmails}
        senderEmail={senderEmail}
      />
    </Box>
  );
};

export default InboxMenu;
