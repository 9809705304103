// Import necessary libraries, components, and utilities
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { PromptInputBoxProps } from '@/types/components/converse/messages';
import {
  convertSpeechToText,
  isSpeechRecognitionSupported,
} from '@/utils/ai/speech/s2t';
import MicrophoneIcon from '@mui/icons-material/MicRounded';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import TunerOptions from './TunerOptionMenu';
import TunerRender from './TunerRender';

/**
 * PromptInputBox component
 *
 * Displays the chat input box with a text field for typing messages.
 * The component also includes buttons for sending messages and enabling speech recognition.
 *
 * @param value - Current value in the input box
 * @param setValue - Callback to update the value in the input box
 * @param onSend - Callback to send the message
 * @param thinking - Boolean to indicate if the assistant is "thinking"
 * @param setThinking - Callback to update the thinking state
 */

const PromptInputBox: React.FC<PromptInputBoxProps> = ({
  value,
  setValue,
  onSend,
  thinking,
  setThinking,
  selectedTuners,
  setSelectedTuners,
}) => {
  // Access the Alchemyst AI store to get alchemyst and theme data
  const alchemyst = useAlchemystStoreForAi((store) => store.alchemyst);
  const [speechRecognitionEnabled, setSpeechRecognitionEnabled] =
    useState<boolean>(false); // Tracks if speech recognition is enabled
  const currentTheme = useAlchemystStoreForAi((store) => store.theme); // Access current theme

  // Updates the value in the input box when the user types
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // Handles key presses; sends the message on "Enter" key press without shift
  const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (thinking) return;
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents a new line when Enter is pressed
      setThinking(true); // Show the assistant is "thinking"
      if (value.trim()) await onSend(); // Send message if there's input text
      setThinking(false);
    }
  };

  console.log('Thinking: ', thinking);

  // Function to start speech recognition and set the recognized text to input box
  const handleSpeechRecognition = async () => {
    try {
      const transcript = await convertSpeechToText(); // Convert speech to text
      setValue(transcript); // Set the recognized text in the input box
    } catch (error) {
      console.error('Error during speech recognition:', error); // Log any errors
    }
  };

  // Check if the browser supports speech recognition on component mount
  useEffect(() => {
    if (window) {
      setSpeechRecognitionEnabled(isSpeechRecognitionSupported());
    }
  }, []);

  // Render the chat input box and action buttons (microphone, send)
  return (
    <Box>
      {/* Container for the tuner chip render selection */}
      <TunerRender
        selectedTuners={selectedTuners}
        setSelectedTuners={setSelectedTuners}
      />
      {/* Container for the input box and action buttons */}
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        p={1}
        gap={1}
        sx={{
          backgroundColor: currentTheme === 'dark' ? '#121212' : '#f5f5f5',
          borderRadius: 5,
        }}
        className={
          currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
        }
      >
        {/* Tuner options menu for selecting tuners */}
        <TunerOptions
          selectedTuners={selectedTuners}
          setSelectedTuners={setSelectedTuners}
        />
        {/* Text input field for typing messages */}
        <TextField
          value={value}
          onChange={handleInputChange}
          placeholder={`Ask ${alchemyst.name}`}
          onKeyDown={handleKeyDown}
          fullWidth
          multiline
          maxRows={4}
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: 'transparent',
              padding: '10px 12px',
              borderRadius: '10px',
              fontSize: '1.1rem',
            },
          }}
          sx={{
            flexGrow: 1,
            mr: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
        />

        {/* Microphone button with tooltip, triggers speech recognition */}
        <Tooltip
          title={!speechRecognitionEnabled ? 'Browser not supported' : ''}
        >
          <span>
            <Button
              onClick={handleSpeechRecognition}
              variant="contained"
              color="primary"
              disabled={!speechRecognitionEnabled || thinking}
              aria-label="Speak to Alchemyst"
              sx={(theme) => ({
                borderRadius: '50%',
                minWidth: '40px',
                minHeight: '40px',
                padding: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  boxShadow: '0 6px 18px rgba(0, 0, 0, 0.25)',
                },
              })}
            >
              <MicrophoneIcon />
            </Button>
          </span>
        </Tooltip>

        {/* Send button to submit the message */}
        <Button
          onClick={async () => {
            setThinking(true);
            await onSend();
            setThinking(false);
          }}
          variant="contained"
          color="primary"
          disabled={!value.trim() || thinking}
          aria-label="Send message"
          sx={(theme) => ({
            borderRadius: '50%',
            minWidth: '40px',
            minHeight: '40px',
            padding: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              boxShadow: '0 6px 18px rgba(0, 0, 0, 0.25)',
            },
          })}
        >
          <SendIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default PromptInputBox;
