import { ChatHistorySkeletonProps } from '@/types/components/converse/history';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * ChatHistorySkeleton component
 *
 * Displays a loading skeleton for chat history while data is being fetched.
 * The skeleton serves as a placeholder to improve user experience by providing
 * a visual indication that content is loading.
 *
 * @param skeletonCount - Number of skeletons to display for chat history loading
 */

const ChatHistorySkeleton: React.FC<ChatHistorySkeletonProps> = ({
  skeletonCount,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      height: '100%',
      padding: 2,
    }}
  >
    {Array.from({ length: skeletonCount }).map((_, index) => (
      <Skeleton key={index} variant="rectangular" width="100%" height={50} />
    ))}
  </Box>
);

export default ChatHistorySkeleton;
