import { inboxCommonStyles } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { setImapCredentials } from '@/utils/imapCredentials';
import Close from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

interface ImapModalProps {
  open: boolean;
  onClose: (event?: {}, reason?: 'escapeKeyDown' | 'backdropClick') => void;
  setIsCredentialsSet: React.Dispatch<React.SetStateAction<boolean>>;
  setInboxAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImapModal: React.FC<ImapModalProps> = ({
  open,
  onClose,
  setIsCredentialsSet,
  setInboxAuthenticated,
}) => {
  const { inboxEmails, setStoreState } = useAlchemystStoreForAi((store) => {
    return {
      inboxEmails: store.inboxEmails,
      setStoreState: store.setStoreState,
    };
  });

  const [emailSettings, setEmailSettings] = useState<{
    imapEmail: string;
    imapPassword: string;
    meetingLink: string;
    loading: boolean;
    error: string;
  }>({
    imapEmail: '',
    imapPassword: '',
    meetingLink: '',
    loading: false,
    error: '',
  });

  /**
   * Validates the email and meeting link inputs.
   * @returns {boolean} - Returns true if inputs are valid, false otherwise.
   */
  const validateInputs = useCallback(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!emailRegex.test(emailSettings.imapEmail)) {
      setEmailSettings((prev) => ({
        ...prev,
        error: 'Invalid email format',
      }));
      return false;
    }
    if (!urlRegex.test(emailSettings.meetingLink)) {
      setEmailSettings((prev) => ({
        ...prev,
        error: 'Invalid meeting link format',
      }));
      return false;
    }
    setEmailSettings((prev) => ({
      ...prev,
      error: '',
    }));
    return true;
  }, [emailSettings.imapEmail, emailSettings.meetingLink]);

  /**
   * Handles the save action by setting IMAP credentials.
   * Sets loading state during the process and updates authentication status.
   */
  const handleSave = useCallback(async () => {
    if (!validateInputs()) return;
    setEmailSettings((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const isSet = await setImapCredentials(
        emailSettings.imapEmail,
        emailSettings.imapPassword,
        emailSettings.meetingLink
      );
      if (isSet) {
        setIsCredentialsSet(true);
        setInboxAuthenticated(true);
        onClose();

        //add to inbox email to store
        const newInboxEmails = [...inboxEmails, emailSettings.imapEmail];
        setStoreState({
          inboxEmails: newInboxEmails,
        });
      } else
        setEmailSettings((prev) => ({
          ...prev,
          error: 'Failed to set IMAP credentials.',
        }));
    } finally {
      setEmailSettings((prev) => ({
        ...prev,
        imapEmail: '',
        imapPassword: '',
        meetingLink: '',
        loading: false,
      }));
    }
  }, [
    emailSettings.imapEmail,
    emailSettings.imapPassword,
    emailSettings.meetingLink,
    onClose,
    setInboxAuthenticated,
    setIsCredentialsSet,
    validateInputs,
    inboxEmails,
    setStoreState,
  ]);

  const handleInputChange =
    (field: Exclude<keyof typeof emailSettings, 'error' | 'loading'>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmailSettings((prev) => ({
        ...prev,
        [field]: event.target.value,
        error: '', // Clear error when input is changed
      }));
    };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="imap-modal-title"
      aria-describedby="imap-modal-description"
      disableEscapeKeyDown={inboxEmails.length === 0}
    >
      <Box
        sx={{
          ...inboxCommonStyles.modalBox,
          width: 800,
          padding: 4,
          position: 'relative',
        }}
      >
        <Typography
          variant="h5"
          id="imap-modal-title"
          sx={{
            marginBottom: 4,
          }}
        >
          Inbox Credentials
        </Typography>

        {inboxEmails.length > 0 && (
          <IconButton
            onClick={onClose}
            size="large"
            sx={{ position: 'absolute', top: 8, right: 8 }}
            aria-label="close"
          >
            <Close />
          </IconButton>
        )}

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginTop={2}
          width="100%"
        >
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={emailSettings.imapEmail}
            onChange={handleInputChange('imapEmail')}
            error={
              !!emailSettings.error && emailSettings.error.includes('email')
            }
            helperText={
              emailSettings.error.includes('email') ? emailSettings.error : ''
            }
            required
          />
          <TextField
            type="text"
            label="Meeting Link"
            variant="outlined"
            fullWidth
            value={emailSettings.meetingLink}
            onChange={handleInputChange('meetingLink')}
            error={
              !!emailSettings.error &&
              emailSettings.error.includes('meeting link')
            }
            helperText={
              emailSettings.error.includes('meeting link')
                ? emailSettings.error
                : ''
            }
            required
          />
          <TextField
            type="password"
            label="App Password"
            variant="outlined"
            fullWidth
            value={emailSettings.imapPassword}
            onChange={handleInputChange('imapPassword')}
            required
          />
          {emailSettings.error && (
            <Typography color="error" variant="body2">
              {emailSettings.error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              emailSettings.loading ||
              !emailSettings.imapEmail ||
              !emailSettings.imapPassword ||
              !emailSettings.meetingLink
            }
            startIcon={emailSettings.loading && <CircularProgress size={20} />}
            fullWidth
          >
            {emailSettings.loading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImapModal;
