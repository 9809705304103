import LeadsImportModal from '@/components/Leads/LeadsImportModal';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import {
  ConvertedData,
  Lead,
  RenderMessageProps,
} from '@/types/components/converse/messages';
import { isSpeechSynthesisSupported, speakText } from '@/utils/ai/speech/t2s';
import { ContentCopy, OpenInNew, VolumeUp } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ChatModal from '../ChatModal';
import { useSession } from '@/hooks/useSession';

const RenderMessage = ({
  role,
  message,
  json,
  animate,
}: RenderMessageProps) => {
  const { alchemyst, setStoreState, snackbarEntries } = useAlchemystStoreForAi(
    (store) => ({
      alchemyst: store.alchemyst,
      setStoreState: store.setStoreState,
      snackbarEntries: store.snackbarEntries,
    })
  );
  // const user = useSession().session?.user;
  const userData = useSession();

  const { log } = useLogging();
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [leadsImportModalOpen, setLeadsImportModalOpen] = useState(false);
  const [chatEmailModalOpen, setChatEmailModalOpen] = useState(false);
  const [localLeads, setLocalLeads] = useState<Lead[]>([]);
  const [localFields, setLocalFields] = useState<string[]>([]);
  const [isLeadsAvailable, setIsLeadsAvailable] = useState(false);
  const convertJsonToForm = (jsonInput: string): ConvertedData | null => {
    try {
      const { searchResults = [] } = JSON.parse(jsonInput);
      if (!searchResults.length) return null;
      const fields = Object.keys(searchResults[0]);
      const leads = searchResults.map((item: Record<string, any>) =>
        fields.reduce(
          (acc: Lead, field) => ({
            ...acc,
            [field]: JSON.stringify(item[field]),
          }),
          {}
        )
      );
      return { leads, fields };
    } catch (error) {
      console.error('Invalid JSON format:', error);
      return null;
    }
  };

  const handleConvertToLeadsPreview = () => {
    const convertedData = convertJsonToForm(json!);
    if (!convertedData) return;

    setIsLeadsAvailable(true);
    const { leads, fields } = convertedData;
    log(
      { message: `${leads.length} Leads generated`, count: leads.length },
      'platform/converse.prospector.leads'
    );
    setLocalLeads(leads);
    setLocalFields(fields);
  };

  useEffect(() => {
    if (json) handleConvertToLeadsPreview();
  }, [json]);

  useEffect(() => {
    if (role === 'assistant' && animate) {
      let index = 0;
      const interval = setInterval(() => {
        setDisplayedMessage(
          (prevMessage) => prevMessage + message[index++] || ''
        );
        if (index >= message.length) clearInterval(interval);
      }, 10);
    } else {
      setDisplayedMessage(message);
    }
  }, [animate, message, role]);

  const handleCopyToClipboard = () => {
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          message: 'Message copied to clipboard!',
          severity: 'success',
          autoHideDuration: 6000,
          type: 'alert',
        },
      ],
    });
    navigator.clipboard.writeText(displayedMessage);
  };

  const borderColor = (theme: any) =>
    role === 'user' ? theme.palette.primary.main : theme.palette.primary.light;

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      mb={2}
      justifyContent={role === 'user' ? 'flex-end' : 'flex-start'}
    >
      {role !== 'user' && (
        <Avatar sx={{ mr: 2 }} src={alchemyst.avatarUrl}>
          {alchemyst.name[0]}
        </Avatar>
      )}
      <Box
        px={2}
        py={1}
        maxWidth="60%"
        borderRadius={3}
        bgcolor={borderColor}
        // color="text.primary"
        boxShadow={1}
        display="flex"
        flexDirection="column"
        color="black"
      >
        <Typography variant="caption">
          {role !== 'user' ? alchemyst.name : userData.data?.given_name}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{ wordWrap: 'break-word' }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {displayedMessage}
          </ReactMarkdown>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {role === 'assistant' && displayedMessage && (
              <StyledButton
                variant="text"
                color="inherit"
                onClick={() => speakText(displayedMessage)}
                disabled={!isSpeechSynthesisSupported()}
                sx={{ mr: 'auto' }}
              >
                <VolumeUp />
              </StyledButton>
            )}
            <StyledButton
              variant="text"
              color={'inherit'}
              onClick={handleCopyToClipboard}
              sx={{ ml: 'auto' }}
            >
              <ContentCopy />
            </StyledButton>
          </Box>
          {isLeadsAvailable && (
            <StyledButton
              variant="outlined"
              color="inherit"
              onClick={() => setLeadsImportModalOpen(true)}
              sx={{ mb: 1, borderRadius: 4, py: 0.5, px: 1 }}
            >
              See leads preview <OpenInNew />
            </StyledButton>
          )}
          {displayedMessage.toLowerCase().includes('subject:') && (
            <StyledButton
              variant="outlined"
              color="inherit"
              onClick={() => setChatEmailModalOpen(true)}
              sx={{ mb: 1, borderRadius: 4, py: 0.5, px: 1 }}
            >
              Send email <OpenInNew />
            </StyledButton>
          )}
        </Typography>
      </Box>
      {role === 'user' && (
        <Avatar sx={{ ml: 2 }} src={userData.data?.picture || ''}>
          U
        </Avatar>
      )}
      <LeadsImportModal
        open={leadsImportModalOpen}
        setOpen={setLeadsImportModalOpen}
        localLeads={localLeads}
        localFields={localFields}
      />
      <ChatModal
        open={chatEmailModalOpen}
        emailContent={displayedMessage}
        setOpen={setChatEmailModalOpen}
      />
    </Box>
  );
};

export default RenderMessage;
