import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions/transition';
import { ReactElement, Ref, forwardRef } from 'react';

const SlideUpTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default SlideUpTransition;
