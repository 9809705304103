import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import {
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Drawer from '@mui/material/Drawer/Drawer';
import { useState } from 'react';
import LeadsSideSheetTabs from './LeadsSideSheetTabs';

interface LeadsSideSheetProps {
  children: React.ReactNode;
  panelWidth: string;
}

const LeadsSideSheet = ({ children, panelWidth }: LeadsSideSheetProps) => {
  const isNavBarDrawerOpen = useAlchemystStoreForAi(
    (store) => store.isNavBarDrawerOpen
  );
  const [open, setOpen] = useState<boolean>(true);

  const calculateWidth = (leftDrawerOpen: boolean, rightSheetOpen: boolean) => {
    if (leftDrawerOpen && rightSheetOpen) return `calc(80vw - ${panelWidth})`;

    if (leftDrawerOpen) return `calc(80vw)`;

    if (rightSheetOpen) return `calc(92vw - ${panelWidth})`;

    return '90vw';
  };
  return (
    <>
      <Drawer
        sx={{
          overflowX: 'hidden',
          width: open ? panelWidth : 40,
          border: 'none',
          transition: 'all 0.3s',
          flexShrink: 0,
          '& .MuiDrawer-paperAnchorRight': {
            border: 'none',
            width: open ? panelWidth : 40,
          },
          '& .MuiDrawer-paper': {
            width: open ? panelWidth : 40,
            backgroundColor: 'transparent',
          },
        }}
        variant="persistent"
        anchor="right"
        open
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              backgroundColor: 'transparent',
            }}
          >
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{
                cursor: 'pointer',
                color: 'white',
                borderRadius: '10px 0 0 10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '0.2rem',
              }}
            >
              {open ? (
                <KeyboardDoubleArrowRightRounded sx={{ fontSize: '2rem' }} />
              ) : (
                <KeyboardDoubleArrowLeftRounded sx={{ fontSize: '2rem' }} />
              )}
            </IconButton>
          </Box>
          <LeadsSideSheetTabs panelWidth={panelWidth} />
        </Box>
      </Drawer>

      <Box
        sx={{
          marginRight: open ? panelWidth : 0,
          maxWidth: calculateWidth(isNavBarDrawerOpen, open),
          flex: 1,
          // alignItems: "cen"
          transition: 'all 0.3s',
        }}
        // width={
        //   open || isNavBarDrawerOpen
        //     ? open && isNavBarDrawerOpen
        //       ? "lg"
        //       : "100%"
        //     : `100%`
        // }
      >
        {children}
      </Box>
    </>
  );
};

export default LeadsSideSheet;
