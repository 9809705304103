import Alert from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
export function CircularProgressMessage(
  props: CircularProgressProps & {
    value: number;
    message: string;
    onClose: () => void;
  }
) {
  return (
    <Alert
      severity="info"
      component={'aside'}
      onClose={props.onClose}
      sx={{
        marginY: 1,
        justifyContent: 'space-between',
        width: '20vw',
      }}
    >
      <Box
        flexDirection={'row'}
        flex={1}
        display={'flex'}
        // rowGap={3}
        sx={{
          justifyContent: 'space-between',
          alignContent: 'space-between',
          gap: '2.5vw',
          verticalAlign: 'baseline',
        }}
      >
        <CircularProgress
          variant={props.value == 0 ? 'indeterminate' : 'determinate'}
          {...{ ...props, message: undefined }}
          sx={{
            maxHeight: '2.5vh',
            maxWidth: '2.5vh',
          }}
        />
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignContent: 'space-between',
            justifyContent: 'left',
            flexDirection: 'column',
            textAlign: 'left',
          }}
        >
          <Typography
            variant="caption"
            sx={{ color: 'text.secondary' }}
          >{`${Math.round(props.value)}%`}</Typography>
          <Typography variant="caption">{props.message}</Typography>
        </Box>
      </Box>
    </Alert>
  );
}
