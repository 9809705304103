import { defaultAlchemysts, sampleEmailInboxCredentials } from '@/constants';
import { AppContextStateForAi, SnackBarEntry } from '@/types/appStates';
import { create } from 'zustand';

const useAlchemystStoreForAi = create<AppContextStateForAi>((set) => ({
  // Initial state setup
  alchemyst: defaultAlchemysts[0],
  isNavBarDrawerOpen: true,
  isNavBarDrawerPresent: false,
  drawerComponents: [],
  theme: 'dark',
  settings: {
    isFullAutopilot: false,
    leadsMatchCriteria: {
      countries: [],
      jobTitles: [],
      sectors: [],
      numberOfEmployees: {
        min: NaN,
        max: NaN,
      },
    },
    outboundKeywords: [],
    calendlyLink: '',
  },
  leads: [],
  fields: [],
  selectedLeads: [],
  campaignAgenda: '',
  inboxEmails: [],
  snackbarEntries: [],
  emailInboxCredentials: sampleEmailInboxCredentials,
  currentScrapingSession: null,
  alchemystCopilotAction: null,
  currentFieldNames: [],
  currentAugmentorOutput: null,
  emailBodiesByThreadByEmail: {},
  emailsToCheck: [],
  threadIds: [],
  inboxAuthenticated: false,
  progressLoading: 0,
  selectedThread: '',
  preparingThreadLoading: false,
  narratives: [],
  isResearchMode: false,

  // Generic setState method
  setStoreState: (newState: Partial<AppContextStateForAi>) =>
    set((state) => ({ ...state, ...newState })),
}));

const updateSnackbarEntry = (entryUpdated: Partial<SnackBarEntry>) => {
  const snackbarEntries = useAlchemystStoreForAi.getState().snackbarEntries;
  const setSnackbarEntries = useAlchemystStoreForAi.getState().setStoreState;
  const entryToBeModified = snackbarEntries.filter(
    (entry) => entry.notificationId !== entryUpdated.notificationId
  );

  if (entryToBeModified.length === 0) {
    console.log('No entry to be modified.');
    return;
  }

  const filteredSnackbarEntries = snackbarEntries.filter(
    (entry) => entry.notificationId !== entryUpdated.notificationId
  );
  setSnackbarEntries({ snackbarEntries: filteredSnackbarEntries });
  // const modifiedSnackbarEntries = snackbarEntries.map(snackbarEntry => {
  //   if (
  //     snackbarEntry.notificationId === entryUpdated.notificationId &&
  //     !!entryUpdated.notificationId
  //   ) {
  //     return entryUpdated;
  //   } else {
  //     return snackbarEntry;
  //   }
  // });
  const modifiedSnackbarEntries = [
    ...filteredSnackbarEntries,
    { ...entryToBeModified[0], ...entryUpdated },
  ];

  setSnackbarEntries({ snackbarEntries: modifiedSnackbarEntries });
};

export { updateSnackbarEntry };

export default useAlchemystStoreForAi;
