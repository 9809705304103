import { Email } from '@/types/email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import Moment from 'react-moment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { FC } from 'react';

type EmailAccordionProps = {
  email: Email;
};

const EmailAccordion: FC<EmailAccordionProps> = ({
  email: { subject, date, body },
}) => {
  const theme = useTheme();

  return (
    <Accordion
      defaultExpanded
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        borderRadius: 1,
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="email-content"
        id="email-header"
        sx={{
          paddingX: 2,
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <MailOutlineIcon fontSize="small" color="primary" />
            <Typography fontSize={20} sx={{ fontWeight: 'bold' }}>
              {subject}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CalendarTodayIcon fontSize="small" color="action" />
            <Typography fontSize={14} color="text.secondary">
              <Moment fromNow>{date}</Moment>
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <Typography
          sx={{
            marginBottom: '1rem',
            fontSize: '1rem',
            lineHeight: 1.6,
            whiteSpace: 'pre-line',
            color: theme.palette.text.primary,
          }}
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EmailAccordion;
