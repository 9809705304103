import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Card, CardContent, Skeleton, Typography } from '@mui/material';
import React from 'react';

interface NumberCardProps {
  number: number;
  title: string;
  icon: React.ReactNode;
  isUp: boolean;
  change: number;
  changeMessage: string;
  changePercentage: number;
  loading: boolean; // Loading state
  hasData: boolean; // Data availability state
}

const NumberCard: React.FC<NumberCardProps> = ({
  number,
  title,
  icon,
  isUp,
  change,
  changeMessage,
  changePercentage,
  loading,
  hasData,
}) => {
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  return (
    <Card
      variant="elevation"
      sx={{
        padding: 1,
        borderRadius: 4,
        background:
          'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
        cursor: 'pointer',
      }}
      className={
        currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
      }
    >
      <CardContent>
        {loading ? (
          // Skeleton loader for loading state
          <>
            <Skeleton variant="text" width={120} height={40} />
            <Skeleton variant="text" width={60} />
            <Skeleton variant="rectangular" width="100%" height={20} />
          </>
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* Show "Not enough data" if no data is available */}
              <Typography variant={hasData ? 'h3' : 'h6'} fontWeight="semibold">
                {hasData ? number.toLocaleString() : 'Not enough data'}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {icon}
              </Box>
            </Box>
            <Typography variant="subtitle1">{title}</Typography>

            <Box display="flex" alignItems="center" mt={1}>
              {/* Show arrow based on whether the data is up or down, but default to "up" if there's no data */}
              {isUp || !hasData ? (
                <ArrowUpwardIcon color="success" fontSize="small" />
              ) : (
                <ArrowDownwardIcon color="error" fontSize="small" />
              )}
              {/* Show zero values if no data is available */}
              <Typography
                variant="subtitle1"
                color={
                  hasData
                    ? isUp
                      ? 'success.main'
                      : 'error.main'
                    : 'textSecondary'
                }
                fontWeight="bold"
                ml={0.5}
              >
                {hasData ? change.toFixed(1) : '0.0'}
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                +{hasData ? changePercentage.toFixed(2) : '0.00'}%{' '}
                {changeMessage}
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default NumberCard;
