import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AugmentScraper from './scraper';

import { ScrapingSession } from '@/types/augment';

import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import SlideUpTransition from '../animations/transition/SlideUp';

interface ScrapingHistoryEntryProps {
  scrapingSessionInfo: ScrapingSession;
  scrapeId: string;
}

const getScrapingHistory = async () => {
  let scrapingResults = await fetchWithRewrites('/api/augment', {
    method: 'GET',
    signal: createTimeoutSignal(),
  });

  let scrapingResultsJson = await scrapingResults.json();

  console.log('Scraping Results Responses JSON = ');
  console.log(scrapingResultsJson);

  return scrapingResultsJson;
};

const ScrapingHistoryEntry = ({
  scrapingSessionInfo,
  scrapeId,
}: ScrapingHistoryEntryProps) => {
  console.log('Key value received = ', scrapeId);
  // const [token, setToken] = useState<string | null>(null);

  // const { getToken } = fetchWithRewrites(;
  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  return (
    <Box
      key={scrapeId}
      flexDirection={'row'}
      display={'flex'}
      width={'auto'}
      justifyContent={'space-between'}
      justifyItems={'stretch'}
      alignItems={'center'}
      border={2}
      borderColor={'azure'}
      borderRadius={2}
      margin={1}
      paddingX={3}
      paddingY={2}
    >
      <Box>
        <Typography variant="body1">
          Scrape ID {scrapeId ?? 'unknown'}
        </Typography>
        <Typography variant="body1">
          Scrape on {scrapingSessionInfo?.metadata?.pageUrl ?? 'unknown url'}
        </Typography>
        <Typography variant="body1">
          Created at {scrapingSessionInfo?.createdAt ?? 'an unknown time'}
        </Typography>
      </Box>
      <Button href={`/augment/${scrapeId}`}>Go</Button>
    </Box>
  );
};

const AugmentComponent = () => {
  const [scraperState, setScraperState] = useState<
    'closed' | 'working' | 'open' | 'saved'
  >('closed');
  const [sessions, setSessions] = useState<any>(null);
  // const [token, setToken] = useState<string | null>(null);
  // const { getToken } = fetchWithRewrites(;

  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  useEffect(() => {
    getScrapingHistory().then((data) => {
      console.log('Received data');
      console.log('Data = ');
      console.log(data);
      setSessions(data);
    });
  }, []);

  useEffect(() => console.log(sessions), [sessions]);

  return (
    <Stack maxWidth={'75vw'}>
      <Stack spacing={1} marginY={3} paddingY={3}>
        <Box
          display="flex"
          flexDirection={'row'}
          justifyContent={'space-between'}
          maxWidth={'75vw'}
        >
          <Stack spacing={2}>
            <Typography variant="h3">Augmentor</Typography>
            <Typography variant="body1">
              Transform chaos into clarity with Augmentor: Scrape unstructured
              data and mold it into the precise format you need, effortlessly.
            </Typography>
          </Stack>
          <Box marginY={2}>
            <Button
              variant="outlined"
              sx={{
                paddingY: 2,
              }}
              onClick={() => setScraperState('open')}
            >
              New Scrape
            </Button>
          </Box>
        </Box>
      </Stack>
      <Dialog
        fullScreen
        open={scraperState !== 'closed'}
        TransitionComponent={SlideUpTransition}
      >
        <AugmentScraper
          setScrapingStatus={setScraperState}
          scrapingStatus={scraperState}
        />
      </Dialog>
      <Stack spacing={2} marginY={3} paddingY={3}>
        <Typography variant={'h3'}>Past Sessions</Typography>
        <Typography variant={'body1'}>
          Browse through the older scraping sessions and their results.
        </Typography>
      </Stack>
      {(!sessions || sessions === '{}') && (
        <Typography variant={'body1'}>No sessions saved yet</Typography>
      )}
      <Stack
        spacing={2}
        maxHeight={'45vh'}
        maxWidth={'100%'}
        sx={{
          scrollBehavior: 'smooth',
          overflow: 'scroll',
          scrollbarWidth: 'none',
        }}
      >
        {Array.isArray(sessions) &&
          sessions.map((session) => (
            <ScrapingHistoryEntry
              scrapingSessionInfo={{
                data: session.data,
                metadata: session.metadata,
                userId: session.userId,
                createdAt: session.createdAt,
              }}
              scrapeId={session._id}
              key={session._id}
            />
          ))}
      </Stack>
    </Stack>
  );
};

export default AugmentComponent;
