import { useRoutes } from 'raviger';
import RootLayout from './components/layout/RootLayout';
import { routes } from './routes/routes';

// Main App Component
// const match = document.cookie.match(/(^| )accessToken=([^;]+)/);
// console.log(match[2], 'the match');
const App = () => {
  const route = useRoutes(routes);

  return <RootLayout>{route}</RootLayout>;
};

export default App;
