import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { ContactMailTwoTone } from '@mui/icons-material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

const LeftEmailThreadBar: FC = () => {
  const theme = useTheme();

  const { emailBodiesByThreadByEmail, selectedThread, setStoreState } =
    useAlchemystStoreForAi((store) => {
      return {
        emailBodiesByThreadByEmail: store.emailBodiesByThreadByEmail,
        selectedThread: store.selectedThread,
        setStoreState: store.setStoreState,
      };
    });

  return (
    <Box
      sx={{
        padding: 2,
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: 'calc(82vh - 64px)',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {Object.keys(emailBodiesByThreadByEmail).map((email) => (
        <Accordion key={email} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreRoundedIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <ContactMailTwoTone />{' '}
            <Typography>
              &nbsp;&nbsp;{email.substring(0, 25)}
              {email.length > 25 ? '...' : ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
          >
            {emailBodiesByThreadByEmail[email].map((thread, index) => {
              const threadId = Object.keys(thread)[0];
              return (
                <Typography
                  key={index}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedThread === threadId
                        ? theme.palette.action.selected
                        : theme.palette.background.paper,
                    padding: theme.spacing(1),
                    transition: 'color 0.2s, background-color 0.2s',
                    '&:hover': {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.action.hover,
                    },
                    color:
                      selectedThread === threadId
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    borderRadius: '4px',
                  }}
                  onClick={() => setStoreState({ selectedThread: threadId })}
                >
                  {thread[threadId][0].subject}
                </Typography>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default LeftEmailThreadBar;
