import generateBreadCrumbs from '@/utils/generateBreadCrumbs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

type BreadcrumbsBarProps = {
  link: string;
};

const BreadcrumbsBar: React.FC<BreadcrumbsBarProps> = ({ link }) => {
  const breadCrumbs = generateBreadCrumbs(link);
  return (
    <div className="my-2 mx-3">
      <Breadcrumbs aria-label="breadcrumb" separator=">">
        {breadCrumbs.map((breadcrumb, index) => {
          return (
            <Link
              key={`breadcrumb-${index + 1}`}
              color="inherit"
              href={breadcrumb.path}
            >
              {breadcrumb.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsBar;
