import { useCallback, useEffect, useState } from 'react';

interface UserInfo {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
  email_verified: boolean;
}

interface SessionState {
  data: UserInfo | null;
  status: 'idle' | 'loading' | 'success' | 'error';
}

const getAccessTokenFromCookies = () => {
  const match = document.cookie.match(/(^| )accessToken=([^;]+)/);
  return match && match[2];
};

const getUserInfoFromGoogleToken = async (
  token: string | undefined
): Promise<UserInfo | null> => {
  if (!token) return null;

  const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    console.error('Failed to fetch user info:', res.statusText);
    return null;
  }

  return (await res.json()) as UserInfo;
};

export const useSession = () => {
  const [session, setSession] = useState<SessionState>({
    data: null,
    status: 'idle',
  });

  const fetchUserInfo = useCallback(async () => {
    const token = getAccessTokenFromCookies();

    if (!token) {
      setSession({ data: null, status: 'idle' });
      return;
    }

    setSession((prev) => ({ ...prev, status: 'loading' }));

    try {
      const userInfo = await getUserInfoFromGoogleToken(token);
      if (userInfo) {
        setSession({ data: userInfo, status: 'success' });
      } else {
        setSession({ data: null, status: 'error' });
      }
    } catch (error) {
      console.error('Error fetching session:', error);
      setSession({ data: null, status: 'error' });
    }
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  return session;
};
