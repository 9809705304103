import { defaultAlchemysts } from '@/constants';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import {
  InitialSuggestedQuestionsProps,
  Question,
} from '@/types/components/converse/messages';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const questions: Question[] = [
  {
    id: 1,
    question:
      'Can you please summarize the contents of: [**Wikipedia Top 25**](https://en.wikipedia.org/wiki/Wikipedia:Top_25_Report) ?',
    icon: '💼',
  },
  {
    id: 2,
    question: 'Who are the top tech investors in London?',
    icon: '🏙️',
    tool: 'prospector',
  },
  {
    id: 3,
    question: 'Can you tell me who are the latest investors in OpenAI?',
    icon: '🚀',
    tool: 'web search',
  },
  {
    id: 4,
    question: 'Help me research about my ICPs',
    icon: '💳',
    tool: '',
  },
];

/**
 * InitialSuggestedQuestions component
 *
 * Displays a set of suggested questions to help users get started with the chatbot.
 * The component provides a visual guide to the user, suggesting common questions
 * that can be asked to the chatbot.
 *
 * @param onSend - Callback to send a message to the chatbot
 */

const InitialSuggestedQuestions: React.FC<InitialSuggestedQuestionsProps> = ({
  onSend,
}) => {
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const handleSend = async (q: Question) => {
    if (q.tool === 'prospector') {
      setStoreState({ isResearchMode: true });
      await onSend(q.question);
    } else {
      await onSend(
        q.question + ((q.tool ?? '').length > 0 ? ` use ${q.tool}` : '')
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        padding: 2,
        height: '100%',
      }}
    >
      <img
        src={defaultAlchemysts[0].avatarUrl}
        alt="maya"
        width={100}
        height={100}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '80%',
          gap: 2,
          padding: 2,
        }}
      >
        {questions.map((q) => (
          <Box
            key={q.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: 2,
              borderRadius: 4,
              backgroundColor: 'rgba(0,0,0,0.1)',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.2)',
              },
            }}
            onClick={() => handleSend(q)}
          >
            <Typography>{q.icon}</Typography>
            <Typography
              sx={{
                color: '#ffffff9x',
              }}
              component={'div'}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {q.question}
              </ReactMarkdown>
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InitialSuggestedQuestions;
