'use client';

import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'raviger';
import { useEffect, useState } from 'react';

const AuthButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [data, setData] = useState<{
    _id: string;
    email: string;
    authenticationMethods: string[];
    otherHandles: string[];
    userType: string;
    __v: number;
    image?: string;
  } | null>(null);

  const handleSignOut = async () => {
    try {
      const response = await fetchWithRewrites('/api/auth/logout', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        navigate('/auth');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignIn = () => {
    navigate('/auth');
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function getUserStatus() {
      const data = await fetchWithRewrites('/api/auth/status', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const response = await data.json();
      setData(response.data);

      console.log(response);
    }

    getUserStatus();
  }, []);

  return (
    <>
      {data ? (
        <>
          <Avatar
            variant="circular"
            id="profile-image"
            src={data.image || data.email.charAt(0).toUpperCase()}
            alt="Picture of the author"
            sx={{
              borderRadius: '100%',
              cursor: 'pointer',
            }}
            onClick={handleMenuOpen}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
            <MenuItem onClick={() => navigate('/settings')}>Settings</MenuItem>
            <hr
              style={{
                width: '80%',
                alignSelf: 'center',
              }}
            />
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </Menu>
        </>
      ) : (
        <Button
          color="primary"
          sx={{
            mx: 2,
          }}
          variant="text"
          onClick={handleSignIn}
        >
          Sign In
        </Button>
      )}
    </>
  );
};

export default AuthButton;
