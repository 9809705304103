import { countryList } from '@/constants/countries';
import { MaterialUISwitch } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { OpenInNew } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'raviger';

const employeeRangeOptions = [
  '0-10',
  '10-50',
  '50-100',
  '100-500',
  '500-1000',
  '1000-5000',
  '5000-10000',
  '10000+',
];

const SettingFields: React.FC = () => {
  const settings = useAlchemystStoreForAi((store) => store.settings);
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = {
      ...settings,
      isFullAutopilot: event.target.checked,
    };
    setStoreState({
      settings: newSettings,
    });
  };

  const handleCriteriaChange = (field: string, value: string[]) => {
    const newSettings = {
      ...settings,
      leadsMatchCriteria: {
        ...settings.leadsMatchCriteria,
        [field]: value,
      },
    };
    setStoreState({ settings: newSettings });
  };

  const handleEmployeeRangeChange = (value: string) => {
    const [min, max] = value
      .split('-')
      .map((v) => (v === '+' ? Number.MAX_VALUE : parseInt(v, 10)));
    const newSettings = {
      ...settings,
      leadsMatchCriteria: {
        ...settings.leadsMatchCriteria,
        numberOfEmployees: { min, max },
      },
    };
    setStoreState({ settings: newSettings });
  };

  const handleKeywordsChange = (event: any, value: string[]) => {
    const newSettings = {
      ...settings,
      outboundKeywords: value,
    };
    setStoreState({ settings: newSettings });
  };

  const handleCalendlyLinkChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newSettings = {
      ...settings,
      calendlyLink: event.target.value,
    };
    setStoreState({ settings: newSettings });
  };

  return (
    <FormGroup
      sx={{
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          padding: 2,
          background:
            'linear-gradient(268.4deg, rgba(255, 160, 91, 0.1) 1.52%, rgba(31, 156, 154, 0.1) 98.82%)',
          borderRadius: 5,
        }}
        className={
          currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
        }
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Enable Autopilot
          </Typography>
          <Typography variant="body2">
            Turn on the autopilot to let the system handle everything for you.
          </Typography>
        </Box>

        <MaterialUISwitch
          checked={settings.isFullAutopilot}
          onChange={handleSwitchChange}
          sx={{ m: 1 }}
        />
      </Box>
      <Box padding={2} marginTop={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Leads Match Criteria
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Typography variant="h5">3500 Matches</Typography>
            <Link href={'/leads'}>
              <Button
                endIcon={<OpenInNew />}
                sx={{
                  padding: '5px 10px',
                  borderRadius: 5,
                  background:
                    'linear-gradient(268.4deg, rgba(255, 160, 91, 0.1) 1.52%, rgba(31, 156, 154, 0.1) 98.82%)',
                }}
              >
                View Matches
              </Button>
            </Link>
          </Box>
        </Box>
        <Autocomplete
          multiple
          value={settings.leadsMatchCriteria.countries}
          onChange={(event, value) => handleCriteriaChange('countries', value)}
          options={countryList}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Countries"
              placeholder="Select countries"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Autocomplete
          multiple
          freeSolo
          value={settings.leadsMatchCriteria.jobTitles}
          onChange={(event, value) => handleCriteriaChange('jobTitles', value)}
          options={[]}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Job Titles"
              placeholder="write job title and press enter"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Autocomplete
          multiple
          freeSolo
          value={settings.leadsMatchCriteria.sectors}
          onChange={(event, value) => handleCriteriaChange('sectors', value)}
          options={[]}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Sectors"
              placeholder="write sector name and press enter"
              fullWidth
              margin="normal"
            />
          )}
        />
      </Box>
      <Box padding={2}>
        <Typography variant="h5" gutterBottom>
          Number of Employees
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Employee Range</InputLabel>
          <Select
            value={
              employeeRangeOptions.find(
                (option) =>
                  parseInt(option.split('-')[0], 10) ===
                  settings.leadsMatchCriteria.numberOfEmployees.min
              ) || ''
            }
            onChange={(e) => handleEmployeeRangeChange(e.target.value)}
            label="Select Employee Range"
          >
            {employeeRangeOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box padding={2}>
        <Typography variant="h5" gutterBottom>
          Outbound Keywords
        </Typography>
        <Autocomplete
          multiple
          freeSolo
          value={settings.outboundKeywords}
          onChange={handleKeywordsChange}
          options={[]}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Enter your area of Expertise/Specialization"
              placeholder="write keyword and press enter"
              fullWidth
              margin="normal"
            />
          )}
        />
      </Box>
      <Box padding={2}>
        <Typography variant="h5" gutterBottom>
          Calendly Link
        </Typography>
        <TextField
          variant="outlined"
          label="Enter your Calendly link"
          fullWidth
          margin="normal"
          value={settings.calendlyLink}
          onChange={handleCalendlyLinkChange}
        />
      </Box>
    </FormGroup>
  );
};

export default SettingFields;
