import { ChatHistoryListProps } from '@/types/components/converse/history';
import groupChatHistory from '@/utils/converse/groupChatHistory';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import ChatHistoryItem from './ChatHistoryItem';

/**
 * ChatHistoryList component
 *
 * Displays a list of chat history items, grouped by categories.
 * Each chat item can be selected, and the component also displays
 * a message if no chat history is available.
 *
 * @param chatHistory - Array of chat history objects to be displayed
 * @param currentChatID - ID of the currently selected chat
 * @param setCurrentChatID - Callback to set the current chat ID on selection
 */

const ChatHistoryList: React.FC<ChatHistoryListProps> = ({
  chatHistory,
  currentChatID,
  setCurrentChatID,
}) => {
  // Memoize grouped chat history to avoid recalculation
  const groupedChatHistory = useMemo(
    () => groupChatHistory(chatHistory),
    [chatHistory]
  );

  // Display a message if no chat history is present
  if (chatHistory.length === 0) {
    return <Typography>No chat history available.</Typography>;
  }

  return (
    <Box>
      {(
        Object.keys(groupedChatHistory) as Array<
          keyof typeof groupedChatHistory
        >
      ).map((groupKey) => (
        <Stack key={groupKey} spacing={1}>
          {groupedChatHistory[groupKey].length > 0 && (
            <>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 'bold',
                  padding: '6px',
                  paddingTop: '16px',
                  color: '#ff9a35',
                }}
              >
                {groupKey[0].toUpperCase() +
                  groupKey.slice(1).replace(/_/g, ' ')}
              </Typography>
              {groupedChatHistory[groupKey]
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((chat) => (
                  <ChatHistoryItem
                    key={chat.id}
                    chat={chat}
                    currentChatID={currentChatID}
                    setCurrentChatID={setCurrentChatID}
                  />
                ))}
            </>
          )}
        </Stack>
      ))}
    </Box>
  );
};

export default ChatHistoryList;
