import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { EventData } from '@/types/calendarEvent';
import { AutoEmail, Email } from '@/types/email';
import { createTimeoutSignal } from '@/utils/signalConfig';
import {
  AutoAwesomeTwoTone,
  Close,
  EditCalendarTwoTone,
  Send,
} from '@mui/icons-material';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import { FC, useState } from 'react';
import EmailAccordion from './EmailAccordion';
import ScheduleEventModal from './ScheduleEventModal';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';

interface RightEmailThreadProps {
  selectedEmails: Email[];
  senderEmail: string;
}

const ReplyBox: FC<RightEmailThreadProps> = ({
  selectedEmails,
  senderEmail,
}) => {
  const [isSendActive, setIsSendActive] = useState<boolean>(false);
  const [eventBtnLoading, setEventBtnLoading] = useState<boolean>(false);
  const [generateBtnLoading, setGenerateBtnLoading] = useState<boolean>(false);
  const [sendBtnLoading, setSendBtnLoading] = useState<boolean>(false);
  const [reply, setReply] = useState<string>('');
  const [eventData, setEventData] = useState<EventData>({
    description: '',
    subject: '',
    startTime: new Date(),
    endTime: new Date(),
    invitees: [],
    meetingLink: '',
    senderEmail: '',
  });
  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);

  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const { addLogs } = useLogging();

  const handleFetchReply = async () => {
    setGenerateBtnLoading(true);
    try {
      const response = await fetchWithRewrites('/api/emails/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          selectedEmails.map((email) => ({
            from: email.to,
            to: email.from,
            text: email.text,
            date: email.date,
          }))
        ),
        signal: createTimeoutSignal(),
      });

      if (response.ok) {
        const replyJson = await response.json();
        setReply(replyJson.text);
        setIsSendActive(true);
      } else {
        console.error('Error generating reply');
      }
    } catch (error) {
      console.error('Network error:', error);
    } finally {
      setGenerateBtnLoading(false);
    }
  };

  const handleDiscard = () => {
    setReply('');
    setIsSendActive(false);
  };

  const handleAddReplyToLocalStorage = () => {
    setSendBtnLoading(true);

    const _reply: AutoEmail = {
      subject: `Re: ${selectedEmails[0].subject}`,
      date: new Date().toISOString(),
      from: selectedEmails[0].to,
      to: selectedEmails[0].from,
      body: reply,
      approved: false,
    };

    try {
      const emails = localStorage.getItem('autoEmails');
      const emailsArray = emails ? JSON.parse(emails) : [];
      emailsArray.push(_reply);
      localStorage.setItem('autoEmails', JSON.stringify(emailsArray));

      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'success',
            message: 'Reply added to automated emails.',
            type: 'alert',
          },
        ],
      });

      // Add log
      addLogs([
        {
          message: 'Reply added to automated emails.',
          timestamp: Date.now(),
          context: 'inbox:sent-email',
          metadata: { email: _reply },
        },
      ]);
    } catch (error) {
      console.error('Error saving reply:', error);
    } finally {
      setReply('');
      setIsSendActive(false);
      setSendBtnLoading(false);
    }
  };

  // Events

  const addMinutesToDate = (dateString: string, minutes: number): Date => {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  };

  const handleFetchEventData = async () => {
    setEventBtnLoading(true);
    try {
      const response = await fetchWithRewrites('/api/events/native/check', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emails: selectedEmails }),
        signal: createTimeoutSignal(),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const result = await response.json();
      const eventData: EventData = {
        description: result.event.description,
        subject: result.event.subject,
        startTime: result.event.startTime,
        endTime: addMinutesToDate(result.event.startTime, 60),
        invitees: [selectedEmails[0].from, selectedEmails[0].to],
        meetingLink: result.event.meetingLink ?? '',
        senderEmail:
          selectedEmails[0].to === senderEmail
            ? selectedEmails[0].from
            : selectedEmails[0].to,
      };
      setEventData(eventData);
      setIsEventModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch event data:', error);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: 'Failed to fetch event data.',
            type: 'alert',
          },
        ],
      });
    }
    setEventBtnLoading(false);
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        zIndex: 1,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <TextField
          multiline
          rows={isSendActive ? 16 : 1.75}
          fullWidth
          placeholder="Click on generate reply to start composing your email..."
          value={reply}
          onChange={(e) => setReply(e.target.value)}
          disabled={!isSendActive}
          InputProps={{
            endAdornment: isSendActive && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                }}
              >
                <IconButton onClick={handleDiscard}>
                  <Close />
                </IconButton>
              </Box>
            ),
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            marginTop: 1,
            position: 'absolute',
            right: 16,
            bottom: 16,
          }}
        >
          <StyledButton
            variant="contained"
            sx={{
              padding: '6px 20px',
              '&:disabled': {
                backgroundColor: 'background.main',
              },
            }}
            startIcon={
              eventBtnLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <EditCalendarTwoTone />
              )
            }
            size="medium"
            disabled={eventBtnLoading}
            onClick={handleFetchEventData}
          >
            {eventBtnLoading ? 'Checking...' : 'Create Event'}
          </StyledButton>
          <StyledButton
            variant="contained"
            sx={{
              padding: '8px 26px',
              '&:disabled': {
                backgroundColor: 'background.main',
              },
            }}
            startIcon={
              generateBtnLoading || sendBtnLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : isSendActive ? (
                <Send />
              ) : (
                <AutoAwesomeTwoTone />
              )
            }
            disabled={generateBtnLoading || sendBtnLoading}
            // no fade on disable
            disableRipple={generateBtnLoading || sendBtnLoading}
            disableElevation={generateBtnLoading || sendBtnLoading}
            disableFocusRipple={generateBtnLoading || sendBtnLoading}
            size="medium"
            onClick={
              isSendActive ? handleAddReplyToLocalStorage : handleFetchReply
            }
          >
            {generateBtnLoading
              ? 'Generating...'
              : sendBtnLoading
                ? 'Sending...'
                : isSendActive
                  ? 'Send'
                  : 'Generate Reply'}
          </StyledButton>
        </Box>
      </Box>
      {eventData && (
        <ScheduleEventModal
          open={isEventModalOpen}
          onClose={() => setIsEventModalOpen(false)}
          eventData={eventData}
          setEventData={setEventData}
        />
      )}
    </Box>
  );
};

const RightEmailThread: FC<RightEmailThreadProps> = ({
  selectedEmails,
  senderEmail,
}) => (
  <Box
    sx={{
      padding: 2,
      width: '75%',
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(82vh - 64px)',
      backgroundColor: 'background.paper',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        overflowY: 'auto',
        paddingBottom: 4, // To ensure content does not overlap with sticky ReplyBox
      }}
    >
      {selectedEmails.map((email, index) => (
        <EmailAccordion key={index} email={email} />
      ))}
    </Box>
    <ReplyBox selectedEmails={selectedEmails} senderEmail={senderEmail} />
  </Box>
);

export default RightEmailThread;
