import { EventData } from '@/types/calendarEvent';
import { createTimeoutSignal } from '../signalConfig';
import { fetchWithRewrites } from '../fetchWithRewrites';

/** Change Date.ISOString to GCal string - one without ":", "-" or "."
 * This method is implemented because .replace/.replaceAll was throwing an error as "startTime.replaceAll is not a function" 🥲
 * @param {string} time
 * @returns {string} The time in Google Calendar Format.
 */
const getGCalTime = (time: string) => {
  let transformedTime = time.slice(0, -4);

  let newString = '';
  for (let i = 0; i < transformedTime.length; i++) {
    const char = transformedTime[i];
    if (char !== ':' && char !== '.' && char !== '-') {
      newString += char;
    }
  }

  return newString;
};

const generateCalendarEvent = (
  details: {
    subject: string;
    startTime: string | Date;
    endTime: string | Date;
    location: string;
    description: string;
    invitees: string[];
  },
  calendarProvider: 'google' | 'ical'
): string => {
  const { subject, startTime, endTime, location, description, invitees } =
    details;

  const startTimeString =
    startTime instanceof Date ? startTime.toISOString() : startTime;
  const transformedStartTimeString = getGCalTime(startTimeString);

  const endTimeString =
    endTime instanceof Date ? endTime.toISOString() : endTime;
  const transformedEndTimeString = getGCalTime(endTimeString);

  switch (calendarProvider) {
    case 'google':
      const googleCalendarUrl = new URL(
        'https://calendar.google.com/calendar/u/0/r/eventedit'
      );
      googleCalendarUrl.searchParams.append('output', 'xml');
      googleCalendarUrl.searchParams.append('text', subject);
      googleCalendarUrl.searchParams.append(
        'dates',
        `${transformedStartTimeString}/${transformedEndTimeString}`
      );
      googleCalendarUrl.searchParams.append('details', description || '');
      googleCalendarUrl.searchParams.append('location', location || '');
      googleCalendarUrl.searchParams.append('guests', invitees.join(','));
      googleCalendarUrl.searchParams.append('sf', String(true));
      return googleCalendarUrl.toString();

    case 'ical':
      const icalUrl = new URL('https://www.addevent.com/dir/?client=ical');
      icalUrl.searchParams.append('summary', subject);
      icalUrl.searchParams.append('dtstart', transformedStartTimeString);
      icalUrl.searchParams.append('dtend', transformedEndTimeString);
      icalUrl.searchParams.append('description', description || '');
      icalUrl.searchParams.append('location', location || '');
      return icalUrl.toString();

    default:
      throw new Error('Unsupported calendar provider');
  }
};

const sendEmail = async (eventData: EventData) => {
  const {
    invitees,
    subject,
    startTime,
    endTime,
    meetingLink,
    description,
    senderEmail,
  } = eventData;
  console.log('Start time = ', startTime.toISOString());
  console.log('type of start time = ', typeof startTime);
  console.log('End time = ', endTime?.toISOString());
  console.log('type of end time = ', typeof endTime);
  const ERROR_MESSAGES = {
    INVITEES: 'At least two invitees are required',
    REQUIRED_FIELDS: 'Subject, start time, and end time are required',
    EMAIL_FAILED: 'Failed to send email',
  };

  if (!invitees || invitees.length < 2) {
    throw new Error(ERROR_MESSAGES.INVITEES);
  }

  if (!subject || !startTime || !endTime) {
    throw new Error(ERROR_MESSAGES.REQUIRED_FIELDS);
  }

  const inviteesString = invitees.join(', ');
  const emailText = `Hello, ${inviteesString}! You have been invited to an event with the following details:

Subject: ${subject}
Start Time: ${startTime}
End Time: ${endTime}
Meeting Link: ${meetingLink ? meetingLink : 'N/A'}

Please let me know if you have any questions or concerns. Thank you!`;

  const gmeetLink = await generateCalendarEvent(
    {
      subject,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      location: meetingLink ?? '',
      description,
      invitees,
    },
    'google'
  );
  const iCalLink = await generateCalendarEvent(
    {
      subject,
      startTime: startTime,
      endTime: endTime,
      location: meetingLink ?? '',
      description,
      invitees,
    },
    'ical'
  );

  const htmlText = `
    <div style="font-family: Arial, sans-serif; max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; background-color: transparent; color: inherit;">
      <div style="text-align: center; padding-bottom: 20px;">
        <img src="https://alchemyst-updated.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FAI.e605ee6d.png&w=1080&q=75" alt="Event Logo" style="width: 100px;"/>
        <h2 style="color: #4CAF50;">Event Invitation</h2>
      </div>
      <p>Hello, <strong>${inviteesString}</strong>!</p>
      <p>You have been invited to an event with the following details:</p>
      <table style="width: 100%; border-collapse: collapse; margin: 20px 0;">
        <tr>
          <td style="padding: 10px; border: 1px solid #ddd; background-color: #e9f5e9;"><strong>Subject:</strong></td>
          <td style="padding: 10px; border: 1px solid #ddd;">${subject}</td>
        </tr>
        <tr>
          <td style="padding: 10px; border: 1px solid #ddd; background-color: #e9f5e9;"><strong>Start Time:</strong></td>
          <td style="padding: 10px; border: 1px solid #ddd;">${startTime}</td>
        </tr>
        <tr>
          <td style="padding: 10px; border: 1px solid #ddd; background-color: #e9f5e9;"><strong>End Time:</strong></td>
          <td style="padding: 10px; border: 1px solid #ddd;">${endTime}</td>
        </tr>
        <tr>
          <td style="padding: 10px; border: 1px solid #ddd; background-color: #e9f5e9;"><strong>Meeting Link:</strong></td>
          <td style="padding: 10px; border: 1px solid #ddd;">${meetingLink ? `<a href="${meetingLink}" style="color: #4CAF50;">Join Meeting</a>` : 'N/A'}</td>
        </tr>
      </table>
      <div><b><i>Add to your calendars:</i></b></div>
      <div style="display: flex; justify-content: center; gap: 10px; margin: 20px 0;">
        ${!!gmeetLink ? `<a href="${gmeetLink}" style="text-decoration: none; color: white; background-color: #4CAF50; padding: 10px 20px; border-radius: 20px;"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwUEU2rzGl3Vx4EyqC7ZkTTeEuU2p7hGvulA&s" alt="Google Calendar Logo" height="20px" />&nbsp;<span>Google Calendar</span></a>` : ''}
        ${!!iCalLink ? `<a href="${iCalLink}" style="text-decoration: none; color: white; background-color: #4CAF50; padding: 10px 20px; border-radius: 20px;"><img src="https://cdn.jim-nielsen.com/macos/512/calendar-2021-04-29.png?rf=1024" alt="iCal Logo" height="20px"/>&nbsp;<span>iCal</span></a>` : ''}
      </div>
      <h3 style="color: #4CAF50;">Agenda:</h3>
      <ul style="list-style-type: disc; padding-left: 20px; margin: 20px 0;">
        <li>Welcome and Introductions</li>
        <li>Discussion on the main topics</li>
        <li>Q&A Session</li>
        <li>Closing Remarks</li>
      </ul>
      <h3 style="color: #4CAF50;">Additional Information:</h3>
      <p>Please join the meeting on time. If you have any questions or need further information, feel free to contact us.</p>
      <p>We look forward to your participation!</p>
      <p>Best regards,</p>
      <p>${invitees[0]}</p>
      <div style="text-align: center; margin-top: 20px;">
        <a href="https://getalchemystai.com/" style="text-decoration: none; color: white; background-color: #4CAF50; padding: 10px 20px; border-radius: 5px;">Alchemyst AI</a>
      </div>
    </div>
  `;

  const email = {
    sender: senderEmail,
    from: senderEmail,
    to: senderEmail === invitees[0] ? invitees[1] : invitees[0],
    subject: subject,
    text: emailText,
    html: htmlText,
  };

  const response = await fetchWithRewrites('/api/emails/send', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(email),
    signal: createTimeoutSignal(),
  });

  if (!response.ok) {
    throw new Error(ERROR_MESSAGES.EMAIL_FAILED);
  }

  return response.json();
};

export default sendEmail;
