const generateBreadCrumbs = (pathname: string) => {
  const pathnames = pathname.split('/').filter((x) => x);
  const breadCrumbs = pathnames.map((name, index) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
    return {
      name: name.charAt(0).toUpperCase() + name.slice(1),
      path: routeTo,
    };
  });

  return breadCrumbs;
};

export default generateBreadCrumbs;
