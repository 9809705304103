import { ChatHistoryItemProps } from '@/types/components/converse/history';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/**
 * ChatHistoryItem component
 *
 * A list item that represents a single chat history entry.
 * It can be selected to load a specific chat in the interface.
 *
 * @param chat - Chat object containing ID and title for the chat session
 * @param currentChatID - ID of the currently selected chat
 * @param setCurrentChatID - Callback to update the current chat ID on selection
 */

const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({
  chat,
  currentChatID,
  setCurrentChatID,
}) => {
  const isSelected = chat.id === currentChatID;

  return (
    <Box
      key={chat.id}
      onClick={() => setCurrentChatID(chat.id)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') setCurrentChatID(chat.id);
      }}
      sx={{
        padding: '8px',
        cursor: 'pointer',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          transform: 'scale(1.02)',
          transition: 'all 0.2s ease-in-out',
        },
        backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
        transition: 'background-color 0.3s ease',
      }}
      aria-selected={isSelected}
      tabIndex={0} // Makes the item focusable for keyboard users
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: isSelected ? 'bold' : 'normal',
          color: isSelected ? 'primary.main' : 'text.primary',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={chat.title}
      >
        {chat.title}
      </Typography>
    </Box>
  );
};

export default ChatHistoryItem;
