import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import BulkEmail from '../Leads/BulkEmail';

const SalesCampaign = () => {
  const selectedLeads = useAlchemystStoreForAi((store) => {
    if (store.selectedLeads.length === 0) {
      return store.leads;
    }
    return store.selectedLeads;
  });

  return <BulkEmail selectedLeads={selectedLeads} />;
};

export default SalesCampaign;
