import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';

interface CircularProgressWithLabelProps {
  value: number;
  thickness: number;
  color: string;
  size: number;
  sx?: CircularProgressProps['sx'];
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  size,
  thickness,
  color,
  sx,
}) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
    >
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={thickness}
        sx={{ color: color, ...(sx ?? {}) }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {size === 320 && (
          <Typography variant="h4" component="div" color="textPrimary">
            {`${Math.round(value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
