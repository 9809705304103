'use client';

import { CircularProgressMessage } from '@/components/animations/progress/circular';
import MuiXLicense from '@/components/MuiXLicense';
import { darkTheme, lightTheme } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CssBaseline, Snackbar, ThemeProvider } from '@mui/material';
import Alert from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box/Box';
import { } from 'raviger';
import '../../globals.css';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  // const [progress, setProgress] = useState(10);

  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );

  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     console.log("Set interval scheduled.");
  //     setProgress(prevProgress => {
  //       const sampleSnackbarEntry = snackbarEntries
  //         .filter(entry => entry.notificationId === "sample-notification-1")
  //         .pop();
  //       if (sampleSnackbarEntry) {
  //         updateSnackbarEntry({
  //           ...sampleSnackbarEntry,
  //           value: prevProgress,
  //         });
  //       }

  //       return prevProgress >= 100 ? 0 : prevProgress + 10;
  //     });
  //   }, 5000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const handleClose = (snackbarEntry: (typeof snackbarEntries)[0]) => {
    setStoreState({
      snackbarEntries: snackbarEntries.filter(
        (entry) =>
          entry.message !== snackbarEntry.message ||
          entry.notificationId !== snackbarEntry.notificationId
      ),
    });
  };
  return (
    <html lang="en">
      <head>
        <title>Platform - Alchemyst.ai</title>
        <meta name="description" content="Alchemyst Platform" />
        <base href="." />
        <link rel="icon" href="/favicon.ico" />
      </head>
      <body>
        <ThemeProvider theme={currentTheme === 'dark' ? darkTheme : lightTheme}>
          <CssBaseline />
          <MuiXLicense />
          {children}
          <Snackbar
            open={snackbarEntries.length > 0}
            autoHideDuration={
              snackbarEntries
                .map((entry) => entry.autoHideDuration)
                .includes(null) ||
              snackbarEntries
                .map((entry) => entry.autoHideDuration)
                .includes(undefined)
                ? null
                : Math.max(
                    ...snackbarEntries
                      .filter((entry) => typeof entry.value === 'number')
                      .map((entry) => entry.value as number)
                  )
            }
            // onFocus={e => e.persist()}
            sx={{
              zIndex: 1000,
              width: '20vw',
              scrollMarginBlock: 2,
            }}
          >
            <Box
              onFocus={(e) => e.persist()}
              flexDirection={'column'}
              marginTop={3}
              sx={{
                maxHeight: '20vh',
                scrollBehavior: 'smooth',
                overflowY: 'scroll',
                scrollbarWidth: 1,
              }}
            >
              {snackbarEntries.map((snackbarEntry, idx) => {
                switch (snackbarEntry.type) {
                  case 'alert':
                    return (
                      <Alert
                        key={idx}
                        onClose={() => handleClose(snackbarEntry)}
                        severity={snackbarEntry.severity}
                        sx={{
                          width: '100%',
                          marginY: 1,
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {snackbarEntry.message}
                      </Alert>
                    );
                  case 'progress':
                    return (
                      <CircularProgressMessage
                        value={snackbarEntry.value ?? 0}
                        // valueBuffer={snackbarEntry.valueBuffer}
                        onClose={() => handleClose(snackbarEntry)}
                        message={snackbarEntry.message}
                      />
                    );
                  default:
                    return <></>;
                }
              })}
            </Box>
          </Snackbar>
        </ThemeProvider>
      </body>
    </html>
  );
}
