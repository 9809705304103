
import { Header, StyledButton } from '@/constants/themes/index';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { UploadFile } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { useNavigate } from 'raviger';
import { useState } from 'react';
import LeadsSideSheet from './LeadsSideSheet';
import LeadsTableView from './LeadsTableView';

const LeadsComponent: React.FC = () => {
  const navigate = useNavigate();
  const sideSheetpanelWidth: string = '20vw';
  const [leadsLoading, setLeadsLoading] = useState<boolean>(false);
  const { leads, fields, setStoreState } = useAlchemystStoreForAi((store) => {
    return {
      leads: store.leads,
      fields: store.fields,
      setStoreState: store.setStoreState,
    };
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLeadsLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      // Initialize the worker
      const worker = new Worker('/workers/workers/parsecsv.js');
      worker.postMessage({ file });
      // Handle messages from the worker
      worker.onmessage = (e) => {
        const { leads, fields } = e.data;
        setStoreState({
          leads,
          fields,
        });
        setLeadsLoading(false);
        worker.terminate();
      };
      // Handle worker errors
      worker.onerror = (error) => {
        console.error('Worker error:', error);
        setLeadsLoading(false);
        worker.terminate();
      };
    } else {
      setLeadsLoading(false);
    }
  };

  const addColumnHandler = () => {
    const newField = `Column ${fields.length + 1}`;
    const updatedFields = [...fields, newField];
    const updatedLeads = leads.map((lead) => ({ ...lead, [newField]: '' }));

    setStoreState({ fields: updatedFields, leads: updatedLeads });
  };

  const deleteColumnHandler = (fieldToDelete: string) => {
    const updatedFields = fields.filter((field) => field !== fieldToDelete);
    const updatedLeads = leads.map((lead) => {
      const updatedLead = { ...lead };
      delete updatedLead[fieldToDelete];
      return updatedLead;
    });

    setStoreState({ fields: updatedFields, leads: updatedLeads });
  };

  const updateRowHandler = (params: GridRowModel) => {
    console.log('Updating row', params);
    const updatedLeads = leads.map((lead) =>
      lead.id === params.id ? params : lead
    );
    setStoreState({ leads: updatedLeads });
    return params;
  };

  const deleteRowsHandler = (rowsToDelete: number[]) => {
    const updatedLeads = leads
      .filter((lead, index) => !rowsToDelete.includes(index))
      .map((lead, index) => ({ ...lead, id: index }));
    setStoreState({ leads: updatedLeads });
  };

  const addRowHandler = () => {
    const updatedLeads = [...leads, { id: leads.length + 1 }];
    setStoreState({ leads: updatedLeads });
  };

  return (
    <LeadsSideSheet panelWidth={sideSheetpanelWidth}>
      <Header>
        <Box width={'100%'} flex={1} flexGrow={1}>
          <Typography variant="h4" gutterBottom>
            Leads
          </Typography>
          <Typography variant="body2">
            All leads found by Maya and uploaded by you.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <StyledButton
            variant="contained"
            // @ts-ignore
            component="label"
            startIcon={<UploadFile />}
            sx={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '8px',
            }}
          >
            Upload CSV
            <input
              type="file"
              hidden
              accept=".csv"
              onChange={handleFileUpload}
            />
          </StyledButton>
          <Button
            variant="outlined"
            disabled={!leads || leads.length === 0}
            // @ts-ignore
            component="label"
            startIcon={<PeopleIcon />}
            onClick={(e) => {
              navigate('/campaigns');
            }}
            sx={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '8px',
            }}
          >
            To Campaigns
          </Button>
        </Box>
      </Header>
      {leadsLoading ? (
        <Box
          sx={{
            height: '40vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <CircularProgress size={50} />
          <Typography variant="h5">Loading...</Typography>
        </Box>
      ) : (
        <Box sx={{ height: '73vh', width: '100%' }}>
          <LeadsTableView
            leads={leads}
            fields={fields}
            addColumnHandler={addColumnHandler}
            deleteColumnHandler={deleteColumnHandler}
            updateRowHandler={updateRowHandler}
            deleteRowsHandler={deleteRowsHandler}
            addRowHandler={addRowHandler}
            hideFooter={false}
          />
        </Box>
      )}
    </LeadsSideSheet>
  );
};

export default LeadsComponent;
