import { z } from 'zod';

// Base message schema that all message types extend from
const baseMessageSchema = z.object({
  content: z.string(),
  name: z.string().optional(),
  additional_kwargs: z.record(z.unknown()).optional(),
});

// Base message chunk schema
const baseMessageChunkSchema = baseMessageSchema.extend({
  is_chunk: z.literal(true),
  index: z.number(),
});

// Schema for AIMessage
const aiMessageSchema = baseMessageSchema.extend({
  type: z.literal('assistant'),
});

// Schema for AIMessageChunk
const aiMessageChunkSchema = baseMessageChunkSchema.extend({
  type: z.literal('assistant'),
});

// Schema for HumanMessage
const humanMessageSchema = baseMessageSchema.extend({
  type: z.literal('user'),
});

// Schema for HumanMessageChunk
const humanMessageChunkSchema = baseMessageChunkSchema.extend({
  type: z.literal('user'),
});

// Schema for SystemMessage
const systemMessageSchema = baseMessageSchema.extend({
  type: z.literal('system'),
});

// Schema for SystemMessageChunk
const systemMessageChunkSchema = baseMessageChunkSchema.extend({
  type: z.literal('system'),
});

// Union type for all message types
const messageSchema = z.discriminatedUnion('type', [
  aiMessageSchema,
  humanMessageSchema,
  systemMessageSchema,
]);

// Union type for all message chunk types
const messageChunkSchema = z.discriminatedUnion('type', [
  aiMessageChunkSchema,
  humanMessageChunkSchema,
  systemMessageChunkSchema,
]);

const promptVariableSchema = z.object({
  type: z.string(),
  description: z.string().optional(),
});

// Schema for prompt variable definitions
const promptVariablesSchema = z.record(promptVariableSchema);

// Schema for MessagePromptTemplate base
const baseMessagePromptTemplateSchema = z.object({
  prompt: z.object({
    template: z.string(),
    input_variables: z.array(z.string()),
    template_format: z.string().default('f-string'),
    validate_template: z.boolean().default(true),
  }),
});

// Schema for specific message prompt templates
const systemMessagePromptTemplateSchema =
  baseMessagePromptTemplateSchema.extend({
    type: z.literal('system'),
  });

const humanMessagePromptTemplateSchema = baseMessagePromptTemplateSchema.extend(
  {
    type: z.literal('user'),
  }
);

const aiMessagePromptTemplateSchema = baseMessagePromptTemplateSchema.extend({
  type: z.literal('assistant'),
});

// Union of all message prompt template types
const messagePromptTemplateSchema = z.discriminatedUnion('type', [
  systemMessagePromptTemplateSchema,
  humanMessagePromptTemplateSchema,
  aiMessagePromptTemplateSchema,
]);

// Schema for ChatPromptTemplate
const chatPromptTemplateSchema = z.object({
  input_variables: z.array(z.string()),
  messages: z.array(messagePromptTemplateSchema),
  prompt_variables: promptVariablesSchema.optional(),
  template_format: z.string().default('f-string'),
  validate_template: z.boolean().default(true),
  partial_variables: z.record(z.union([z.string(), z.function()])).optional(),
});

// Infer TypeScript types
type PromptVariable = z.infer<typeof promptVariableSchema>;
type PromptVariables = z.infer<typeof promptVariablesSchema>;
type BaseMessagePromptTemplate = z.infer<
  typeof baseMessagePromptTemplateSchema
>;
type SystemMessagePromptTemplate = z.infer<
  typeof systemMessagePromptTemplateSchema
>;
type HumanMessagePromptTemplate = z.infer<
  typeof humanMessagePromptTemplateSchema
>;
type AIMessagePromptTemplate = z.infer<typeof aiMessagePromptTemplateSchema>;
type MessagePromptTemplate = z.infer<typeof messagePromptTemplateSchema>;
type ChatPromptTemplate = z.infer<typeof chatPromptTemplateSchema>;

// Inferred TypeScript interfaces for base types
type BaseMessage = z.infer<typeof baseMessageSchema>;
type BaseMessageChunk = z.infer<typeof baseMessageChunkSchema>;
type AIMessage = z.infer<typeof aiMessageSchema>;
type HumanMessage = z.infer<typeof humanMessageSchema>;
type SystemMessage = z.infer<typeof systemMessageSchema>;
type Message = z.infer<typeof messageSchema>;

// Inferred TypeScript interfaces for chunk types
type AIMessageChunk = z.infer<typeof aiMessageChunkSchema>;
type HumanMessageChunk = z.infer<typeof humanMessageChunkSchema>;
type SystemMessageChunk = z.infer<typeof systemMessageChunkSchema>;
type MessageChunk = z.infer<typeof messageChunkSchema>;

export {
  aiMessageChunkSchema,
  aiMessagePromptTemplateSchema,
  aiMessageSchema,
  baseMessageChunkSchema,
  baseMessagePromptTemplateSchema,
  // Schemas
  baseMessageSchema,
  chatPromptTemplateSchema,
  humanMessageChunkSchema,
  humanMessagePromptTemplateSchema,
  humanMessageSchema,
  messageChunkSchema,
  messagePromptTemplateSchema,
  messageSchema,
  // Schemas
  promptVariableSchema,
  promptVariablesSchema,
  systemMessageChunkSchema,
  systemMessagePromptTemplateSchema,
  systemMessageSchema,
  type AIMessage,
  type AIMessageChunk,
  type AIMessagePromptTemplate,
  // Types
  type BaseMessage,
  type BaseMessageChunk,
  type BaseMessagePromptTemplate,
  type ChatPromptTemplate,
  type HumanMessage,
  type HumanMessageChunk,
  type HumanMessagePromptTemplate,
  type Message,
  type MessageChunk,
  type MessagePromptTemplate,
  // Types
  type PromptVariable,
  type PromptVariables,
  type SystemMessage,
  type SystemMessageChunk,
  type SystemMessagePromptTemplate,
};
