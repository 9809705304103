import { tunerOptions } from '@/constants/tuner_options';
import { TunerOptionMenuProps } from '@/types/components/converse/messages';
import Add from '@mui/icons-material/Add';
import { useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FC, MouseEvent, useState } from 'react';

/**
 * TunerOptionMenu component renders a button to open a menu of tuner options.
 * Each tuner option can be selected to add or remove the corresponding tuner.
 *
 * @component
 * @param {TunerOptionMenuProps} props - The props for the TunerOptionMenu component.
 * @param {string[]} props.selectedTuners - An array of selected tuner keywords.
 * @param {Dispatch<SetStateAction<string[]>>} props.setSelectedTuners - Function to update the selected tuners.
 * @returns {JSX.Element} The rendered TunerOptionMenu component.
 */

const TunerOptionMenu: FC<TunerOptionMenuProps> = ({
  selectedTuners,
  setSelectedTuners,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useTheme();

  const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTunerChange = (keyword: string) => {
    setSelectedTuners((prevSelected) =>
      prevSelected.includes(keyword)
        ? prevSelected.filter((item) => item !== keyword)
        : [...prevSelected, keyword]
    );
  };

  return (
    <FormControl sx={{ m: 1 }}>
      <IconButton
        aria-label="tuner options"
        edge="end"
        size="large"
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          height: '30px',
          width: '30px',
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
        onClick={handleButtonClick}
      >
        <Add />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {tunerOptions.map((tuner) => (
          <MenuItem
            key={tuner.keyword}
            onClick={() => handleTunerChange(tuner.keyword)}
          >
            <Checkbox checked={selectedTuners.includes(tuner.keyword)} />
            <ListItemText primary={tuner.name} />
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export default TunerOptionMenu;
