import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { AppContextStateForAi } from '@/types/appStates';
import { Add, Delete, HourglassEmptyTwoTone } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridToolbar,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import { FC, useCallback, useMemo, useState } from 'react';

interface LeadsTableViewProps {
  leads: AppContextStateForAi['leads'];
  fields: AppContextStateForAi['fields'];
  addColumnHandler?: () => void;
  deleteColumnHandler?: (fieldToDelete: string) => void;
  updateRowHandler?: (params: GridRowModel) => GridRowModel;
  addRowHandler?: () => void;
  deleteRowsHandler?: (rowsToDelete: number[]) => void;
  hideFooter?: boolean;
  showSelection?: boolean;
}

const LeadsTableView: FC<LeadsTableViewProps> = ({
  leads,
  fields,
  addColumnHandler,
  deleteColumnHandler,
  updateRowHandler,
  addRowHandler,
  deleteRowsHandler,
  hideFooter = true,
  showSelection = true,
}) => {
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const renderHeaderWithDelete = useCallback(
    (params: any) => (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">{params.colDef.headerName}</Typography>
        {deleteColumnHandler && (
          <IconButton
            onClick={() => deleteColumnHandler(params.colDef.field)}
            color="error"
            size="small"
            aria-label={`delete column ${params.colDef.headerName}`}
          >
            <Delete fontSize="small" />
          </IconButton>
        )}
      </Stack>
    ),
    [deleteColumnHandler]
  );

  const handleRowSelectionChange = (params: any) => {
    const selectedLeads = params.map((index: number) => leads[index]);
    setStoreState({ selectedLeads });
    setSelectedRows(params);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      {addRowHandler && (
        <StyledButton
          startIcon={<Add />}
          onClick={addRowHandler}
          sx={{ mt: 0.5, p: 0.4 }}
        >
          Add Lead
        </StyledButton>
      )}
      {deleteRowsHandler && selectedRows.length > 0 && (
        <StyledButton
          startIcon={<Delete />}
          onClick={() => deleteRowsHandler(selectedRows)}
          sx={{ mt: 0.5, p: 0.4 }}
        >
          Delete
        </StyledButton>
      )}

      <GridToolbar />
    </GridToolbarContainer>
  );

  const rows = useMemo(
    () => leads.map((lead, index) => ({ id: index, ...lead })),
    [leads]
  );

  const columns: GridColDef[] = useMemo(() => {
    const baseColumns: GridColDef[] = fields
      .filter((field) => field !== 'id')
      .map((field) => ({
        field,
        headerName: field,
        width: 200,
        editable: true,
        renderHeader: renderHeaderWithDelete,
      }));

    if (addColumnHandler) {
      const addColumn: GridColDef = {
        field: 'addColumn',
        headerName: 'Add Column',
        width: 200,
        renderHeader: () => (
          <Button variant="text" startIcon={<Add />} onClick={addColumnHandler}>
            Add Column
          </Button>
        ),
      };
      baseColumns.push(addColumn);
    }
    return baseColumns;
  }, [addColumnHandler, fields, renderHeaderWithDelete]);

  if (leads.length === 0) {
    return (
      <Box
        sx={{
          height: '40vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <HourglassEmptyTwoTone sx={{ fontSize: 50 }} />
        <Typography variant="h5">No leads to display</Typography>
      </Box>
    );
  }

  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      editMode="cell"
      processRowUpdate={updateRowHandler}
      autosizeOnMount
      checkboxSelection={showSelection}
      onRowSelectionModelChange={handleRowSelectionChange}
      hideFooter={hideFooter}
      showCellVerticalBorder
      slots={{ toolbar: CustomToolbar }}
      sx={{
        backgroundColor: 'background.paper',
      }}
    />
  );
};

export default LeadsTableView;
