

import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Container, Typography } from '@mui/material';

interface ComingSoonProps {
  message: string;
}

const ComingSoon: React.FC<ComingSoonProps> = ({ message }) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 15,
      }}
    >
      <SmartToyIcon sx={{ fontSize: 100, color: '#3f51b5', mb: 2 }} />
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
        }}
        component="h1"
        gutterBottom
      >
        COMING SOON
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {message}
      </Typography>
      <Typography variant="body1" color={'gray'} gutterBottom>
        We are working hard to bring you this feature. stay tuned!
      </Typography>
    </Container>
  );
};

export default ComingSoon;
