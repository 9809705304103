import useLogging from '@/hooks/useLogging';
import { UpcomingEvent } from '@/types/dashboard/upcomingEvent';
import { Log } from '@/types/logging';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { Event, ForwardToInbox, Hail, MoveToInbox } from '@mui/icons-material';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';
import AnalyticsCard from './AnalyticsCard';
import EmailQueueCard from './EmailPendingCard';
import Header from './Header';
import MailboxHealth from './MailboxHealth';
import NumberCard from './NumberCard';
import UpcomingEventCard from './UpcomingEventCard';
import { useSession } from '@/hooks/useSession';

// Define reusable type for data states
type DataState = {
  number: number;
  isUp: boolean;
  change: number;
  changePercentage: number;
};

function Dashboard() {
  const [monthlyTarget, setMonthlyTarget] = useState<number>(2347);
  const [maxMonthlyTarget, setMaxMonthlyTarget] = useState<number>(3000);
  const [emailPendingReply, setEmailPendingReply] = useState<number>(45);
  const [upcomingEvents, setUpcomingEvents] = useState<UpcomingEvent[]>([]);

  // Initialize state for various data points with default values
  const [emailSentData, setEmailSentData] =
    useState<DataState>(initDataState());
  const [emailReceivedData, setEmailReceivedData] =
    useState<DataState>(initDataState());
  const [eventScheduledData, setEventScheduledData] =
    useState<DataState>(initDataState());
  const [leadsGeneratedData, setLeadsGeneratedData] =
    useState<DataState>(initDataState());

  const { getLogs } = useLogging();
  const userData = useSession();
  const email = userData.data?.email;

  function initDataState(): DataState {
    return {
      number: 0,
      isUp: true,
      change: 0,
      changePercentage: 0,
    };
  }

  // Reusable function to fetch and set data based on context and log types
  const fetchData = useCallback(
    (
      context: string,
      setData: React.Dispatch<React.SetStateAction<DataState>>,
      logs: Log[]
    ) => {
      const contextLogs = logs.filter((log) => log.context === context);
      const today = filterByToday(contextLogs);
      const yesterday = filterByYesterday(contextLogs);

      setData({
        number: today.length,
        isUp: today.length > yesterday.length,
        change: today.length - yesterday.length,
        changePercentage: calculateChangePercentage(
          today.length,
          yesterday.length
        ),
      });
    },
    []
  );

  // Function to calculate monthly target based on logs
  const getMonthlyEmailSentTarget = (logs: Log[]) => {
    const emailSentLogs = logs.filter(
      (log) => log.context === 'inbox:sent-email'
    );
    const monthlyEmailSent = emailSentLogs.filter(
      (log) =>
        new Date(log.timestamp).getMonth() === new Date().getMonth() &&
        new Date(log.timestamp).getFullYear() === new Date().getFullYear()
    );
    setMonthlyTarget(monthlyEmailSent.length);
  };

  // Fetch logs and update states accordingly
  const fetchLogs = useCallback(() => {
    const logs = getLogs();
    fetchData('inbox:sent-email', setEmailSentData, logs);
    fetchData('inbox:received-email', setEmailReceivedData, logs);
    fetchData('calendar:scheduled-event', setEventScheduledData, logs);
    fetchData('leads:generated', setLeadsGeneratedData, logs);
    getMonthlyEmailSentTarget(logs);
  }, [getLogs, fetchData]);

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Utility to filter logs by today's date
  const filterByToday = (logs: Log[]) =>
    logs.filter(
      (log) =>
        new Date(log.timestamp).toDateString() === new Date().toDateString()
    );

  // Utility to filter logs by yesterday's date
  const filterByYesterday = (logs: Log[]) =>
    logs.filter(
      (log) =>
        new Date(log.timestamp).toDateString() ===
        new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
    );

  // Utility to calculate percentage change between two values
  const calculateChangePercentage = (
    today: number,
    yesterday: number
  ): number => {
    if (yesterday === 0) return today * 100;
    return ((today - yesterday) / yesterday) * 100;
  };

  useEffect(() => {
    const match = document.cookie.match(/(^| )accessToken=([^;]+)/);
    const test = async () => {
      await fetchWithRewrites('/api/test-me', {
        method: 'GET',
      });

      // await fetch('http://localhost:3001/api/test-me', {
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${match[2]}`, // Include the token
      //     'Content-Type': 'application/json', // Optional, depending on your API
      //   },
      // });
    };
    test();
  }, []);

  // Fetch upcoming events from API
  const fetchEvents = useCallback(async () => {
    // const token = await getToken();
    if (!email) return; // Add a check to avoid unnecessary fetch calls
    const response = await fetchWithRewrites('/api/events/native', {
      method: 'POST',
      body: JSON.stringify({ emailId: email }),
      signal: createTimeoutSignal(),
    });
    const data = await response.json();
    if (data.events) {
      const _upcomingEvents = data.events.map((event: any) => ({
        title: event.subject ?? 'Unknown Event',
        timestamp: event.startTime,
      }));
      setUpcomingEvents(_upcomingEvents.slice(0, 4)); // Limit to 4 events
    }
  }, [email]);

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const emails = localStorage.getItem('autoEmails');
    if (emails) {
      setEmailPendingReply(JSON.parse(emails).length);
    }
  }, []);

  const numberCardData = [
    {
      number: emailSentData.number,
      title: 'Email sent today',
      icon: <ForwardToInbox sx={{ fontSize: 40, opacity: 0.8 }} />,
      isUp: emailSentData.isUp,
      change: emailSentData.change,
      changeMessage: 'compared to yesterday',
      changePercentage: emailSentData.changePercentage,
    },
    {
      number: emailReceivedData.number,
      title: 'Email received today',
      icon: <MoveToInbox sx={{ fontSize: 40, opacity: 0.8 }} />,
      isUp: emailReceivedData.isUp,
      change: emailReceivedData.change,
      changeMessage: 'compared to yesterday',
      changePercentage: emailReceivedData.changePercentage,
    },
    {
      number: eventScheduledData.number,
      title: 'Event scheduled today',
      icon: <Event sx={{ fontSize: 40, opacity: 0.8 }} />,
      isUp: eventScheduledData.isUp,
      change: eventScheduledData.change,
      changeMessage: 'compared to yesterday',
      changePercentage: eventScheduledData.changePercentage,
    },
    {
      number: leadsGeneratedData.number,
      title: 'Leads generated today',
      icon: <Hail sx={{ fontSize: 40, opacity: 0.8 }} />,
      isUp: leadsGeneratedData.isUp,
      change: leadsGeneratedData.change,
      changeMessage: 'compared to yesterday',
      changePercentage: leadsGeneratedData.changePercentage,
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Header
        monthlyTarget={monthlyTarget}
        maxMonthlyTarget={maxMonthlyTarget}
      />
      <EmailQueueCard emailsInQueue={emailPendingReply} />
      <Grid container spacing={2} marginY={2}>
        {numberCardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <NumberCard
              number={card.number}
              title={card.title}
              icon={card.icon}
              isUp={card.isUp}
              change={card.change}
              changeMessage={card.changeMessage}
              changePercentage={card.changePercentage}
              loading={false}
              hasData={true}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          marginY: 2,
        }}
      >
        <AnalyticsCard />
        <MailboxHealth />
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', gap: 1, marginY: 2 }}
      >
        <UpcomingEventCard upcomingEvent={upcomingEvents} />
      </Box>
    </Box>
  );
}

export default Dashboard;
