import useScreenDimensions from '@/hooks/useScreenDimensions';
import {
  flattenForRender,
  unslugifyField,
} from '@/utils/ai/tools/prospector/custom';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useState } from 'react';

interface RecommendationResults {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: number;
    hits: {
      _index: string;
      _type?: string;
      _id: string;
      _score: number;
      _source: Record<string, string>;
    }[];
  };
}

const fetchFromRecommender = async ({
  searchParams,
  offset,
  limit,
}: {
  searchParams: Record<string, string | number | (string | number)[]>;
  offset: number;
  limit: number;
}): Promise<RecommendationResults | null> => {
  try {
    const response = await fetchWithRewrites('/api/maya/prospector/manual', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchParams, offset, limit }),
      signal: createTimeoutSignal(),
    });

    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    return null;
  }
};

const Prospector = () => {
  const windowDimensions = useScreenDimensions();
  const [recommendationFilters, setRecommendationFilters] = useState<
    Record<string, string | number | (string | number)[]>
  >({
    occupation: [],
    country: [],
  });
  const [searchState, setSearchState] = useState<
    'idle' | 'loading' | 'results' | 'error'
  >('idle');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [visibleFields, setVisibleFields] = useState<
    { key: string; label: string }[]
  >([]);

  const [recommendationResults, setRecommendationResults] =
    useState<RecommendationResults>({
      took: 0,
      timed_out: false,
      _shards: {
        total: 0,
        successful: 0,
        skipped: 0,
        failed: 0,
      },
      hits: {
        total: {
          value: 0,
          relation: '',
        },
        max_score: 0,
        hits: [],
      },
    });

  return (
    <Stack spacing={5} id="overall-layout" maxWidth={'100%'} marginY={5}>
      <Stack spacing={2} id="recommender-description">
        <Typography variant="h3">Prospector</Typography>
        <Typography variant="body1">
          Find and reach out to leads with ease with Alchemyst Prospector - our
          very own leads Recommender Engine.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <TextField
          label="Name"
          value={recommendationFilters.full_name ?? ''}
          onChange={(event) => {
            setRecommendationFilters({
              ...recommendationFilters,
              full_name: event.target.value,
            });
          }}
        />
        <TextField
          label="Job Titles"
          value={recommendationFilters.occupation ?? ''}
          onChange={(event) => {
            setRecommendationFilters({
              ...recommendationFilters,
              occupation: event.target.value.split(','),
            });
          }}
        />
        <TextField
          label="Country"
          value={recommendationFilters.country_full_name ?? []}
          onChange={(event) => {
            setRecommendationFilters({
              ...recommendationFilters,
              country_full_name: event.target.value.split(',')[0],
            });
          }}
        />
        <TextField
          label="Number of search results to show"
          value={limit ?? 0}
          onChange={(event) => {
            setLimit(parseInt(event.target.value));
          }}
        />
        <Button
          onClick={async () => {
            setSearchState('loading');
            let results = await fetchFromRecommender({
              searchParams: recommendationFilters,
              offset: 0,
              limit,
            });

            setSearchState('results');
            if (!results) {
              console.error('Error fetching data');
              return;
            }

            results.hits.hits = results.hits.hits.map((hit) => {
              return {
                ...hit,
                _source: {
                  ...hit._source,
                  details: undefined,
                  ...JSON.parse(hit._source['details']),
                },
              };
            });
            const commonFields = new Set<string>();
            console.log('Results fields = ');
            console.log(
              results.hits.hits
                .map((hit) => Object.keys(hit._source))
                .flat()
                .forEach((field) => commonFields.add(field))
            );
            console.log('Distinct fields found:');
            console.log(commonFields);
            setVisibleFields(
              Array.from(commonFields).map((field) => ({
                key: field,
                label: unslugifyField(field),
              }))
            );
            setRecommendationResults(results);
          }}
        >
          Search
        </Button>
      </Stack>
      {searchState === 'loading' ? (
        <Stack
          spacing={2}
          textAlign={'center'}
          alignItems={'center'}
          justifyItems={'center'}
          alignSelf={'center'}
        >
          <Typography variant="body1">Loading your results</Typography>
          <CircularProgress variant="indeterminate" color="info" />
        </Stack>
      ) : searchState === 'results' ? (
        <Stack spacing={2}>
          <Typography variant="body2">
            Showing top {recommendationResults.hits?.hits.length ?? 0} results
          </Typography>
          <hr />
          <DataGridPro
            sx={{
              maxWidth: windowDimensions.width * 0.75,
            }}
            checkboxSelection
            pageSizeOptions={[5, 10, 25, 50, 100]}
            pagination
            autoHeight
            rows={(recommendationResults.hits?.hits ?? []).map((hit, idx) => ({
              ...flattenForRender(hit._source),
              id: idx + 1,
            }))}
            columns={visibleFields.map((field) => ({
              field: field.key,
              headerName: field.label,
            }))}
            slots={{ toolbar: GridToolbar }}
          />
        </Stack>
      ) : (
        <Box
          paddingY={5}
          textAlign={'center'}
          alignItems={'center'}
          justifyItems={'center'}
        >
          <Typography variant="body2">
            Start typing for the leads you want and hit the Search button
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default Prospector;
