import { IOSSwitch } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { HumanMessage } from '@/types/ai/dropInLangchain';
import {
  APIResponse,
  ChatMessagesContainerProps,
} from '@/types/components/converse/messages';
import { Message } from '@/types/converse/message';
import {
  createLangchainJsonFromLangchainMessage,
  createLangchainMessageFromDBMessages,
} from '@/utils/ai/conversions';
// import { modifyAttributes } from '@/utils/ai/modifyState';
import { LangChainJSON } from '@/types/ai/messages';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InitialSuggestedQuestions from './InitialSuggestedQuestions';
import PromptInputBox from './PromptInputBox';
import RenderMessage from './RenderMessage';

// Helper function for generating a new message ID
const generateMessageId = (): string => crypto.randomUUID();

/**
 * ChatMessagesContainer component
 *
 * Displays the chat messages and handles sending messages to the API.
 * The component includes the chat history, user input, and the assistant's responses.
 *
 * @param currentChatID - ID of the current chat
 * @param setCurrentChatID - Callback to set the current chat ID
 * @param setChatHistory - Callback to update the chat history
 */

const ChatMessagesContainer: React.FC<ChatMessagesContainerProps> = ({
  currentChatID,
  setCurrentChatID,
  setChatHistory,
}) => {
  const setAlchemystStoreState = useAlchemystStoreForAi(
    (store) => store.setStoreState
  );
  const isResearchMode = useAlchemystStoreForAi(
    (store) => store.isResearchMode
  );
  const [storeChanged, setStoreChanged] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [thinking, setThinking] = useState<boolean>(false);
  const [newMessageIds, setNewMessageIds] = useState<Set<string>>(new Set());
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const showLoading = useRef<boolean>(true);
  const alchemystStore = useAlchemystStoreForAi.getState();
  const [selectedTuners, setSelectedTuners] = useState<string[]>([]); // Tracks the selected tuners

  const [token, setToken] = useState<string | null>(null);

  // const { getToken } = useAuth();

  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  // console.log("TOKEN: ", token);

  const scrollToBottom = useCallback(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Fetch messages when chat ID changes
  const fetchMessages = useCallback(async (id: string) => {
    showLoading.current && setLoadingHistory(true);
    try {
      const messagesResponse = await fetchWithRewrites(
        `/api/chat/fetch/${id}`,
        {
          method: 'GET',
          signal: createTimeoutSignal(),
        }
      );
      const messagesJson: { messages: Message[] } =
        await messagesResponse.json();
      setMessages(messagesJson.messages);
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
    setLoadingHistory(false);
  }, []);

  // Utility to process leads response
  // const processLeads = (jsonRes: any, isUseProspector: boolean): string => {
  //   if (isUseProspector) {
  //     const leadsResponse: LeadsResponse = jsonRes;
  //     return leadsResponse.entityType === null ? getLeads(15) : jsonRes;
  //   }
  //   return '';
  // };

  // //Handle change platform UX
  // const handleChangePlatformUX = useCallback(
  //   async (message: string) => {
  //     const results = await modifyAttributes(message, { alchemystStore }, true);
  //     console.log('Modified Results = ', results);

  //     const previousStoreValues = alchemystStore;

  //     setStoreChanged(true);
  //     setAlchemystStoreState(results.alchemystStore);

  //     const confirmChange = window.confirm(
  //       'The UI has been changed. Do you want to keep it?'
  //     );

  //     if (!confirmChange) setAlchemystStoreState(previousStoreValues);
  //   },
  //   [alchemystStore, setAlchemystStoreState]
  // );

  // handle generating title bassed on the chat history
  const generateTitle = useCallback(
    async (chat_history: LangChainJSON[]): Promise<APIResponse> => {
      console.log('Token in generate func: ', token);
      const res = await fetchWithRewrites('/api/chat/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_history,
          chatId: currentChatID.length > 0 ? currentChatID : undefined,
          researchMode: isResearchMode,
          stream: false,
        }),
        signal: createTimeoutSignal(),
      });

      console.log('Result status = ', res.status);

      if (!res.ok) {
        throw new Error('Failed to generate response');
      }

      const response: APIResponse = await res.json();
      return response;
    },
    [currentChatID, isResearchMode]
  );

  /**
   * Function to handle sending a message in the chat.
   * Processes the user message, sends it to the API, updates the UI with the response,
   * and handles special cases like "change platform ux" commands.
   */
  const handleSendMessage = useCallback(
    async (direct_message?: string) => {
      // Use the provided message or the current message in the input
      let messageContent = direct_message || currentMessage;

      // Return if the message is empty or whitespace
      if (!messageContent.trim()) return;

      // add the tuners prompt to the message
      selectedTuners?.forEach((tuner) => {
        messageContent += ` ${tuner}`;
      });

      // Special command to trigger UX changes based on the message content
      // if (messageContent.toLowerCase().includes('change platform ux')) {
      //   handleChangePlatformUX(messageContent); // Calls a function to modify the UX
      //   return; // Stop further processing of this message
      // }

      // Indicate that the assistant (AI) is "thinking" (i.e., processing the message)
      setThinking(true);

      // Generate a unique ID for the new user message and add it to the current messages
      const newMessageId = generateMessageId();
      const updatedMessages: Message[] = [
        ...messages,
        { id: newMessageId, role: 'user', message: messageContent, json: '' },
      ];
      setMessages(updatedMessages); // Update the messages state
      setNewMessageIds((prev) => new Set([...prev, newMessageId])); // Track the new message ID for animation
      setCurrentMessage(''); // Clear the input field for the next message

      // // Check if the message requests "prospector" functionality, which triggers lead processing
      // const isUseProspector = messageContent
      //   .toLowerCase()
      //   .includes('use prospector');

      // Prepare message data for the API by converting the user message into a format that LangChain uses
      const userMessage: HumanMessage = {
        content: messageContent,
        type: 'user',
      };
      // const messageJson = JSON.parse(JSON.stringify(userMessage));
      const messageBody = [
        ...createLangchainMessageFromDBMessages(updatedMessages), // Combine previous messages
        // ...createMessageFromLangchainJson([messageJson as LangChainJSON]), // Add the new message
        userMessage,
      ];

      console.log(messageBody[0], 'message body hehe');

      try {
        // Send the message to the API and wait for the response
        const response: APIResponse = await generateTitle(
          createLangchainJsonFromLangchainMessage(messageBody)
        );

        // If there's no current chat, create a new one with an ID and title
        if (!currentChatID) {
          const { chatId, title } = response;
          setChatHistory((prev) => [
            ...prev,
            { id: chatId, title, timestamp: Date.now() },
          ]);
          setCurrentChatID(chatId); // Set the new chat as the current chat
        }

        // Get the assistant's reply content and process any leads if using "prospector"

        //TODO refactor type error for generate repsonse
        // const assistantMessageContent: string = response.result.response
        //   .lc_kwargs
        //   ? response.result.response.lc_kwargs
        //   : (response.result.response as any).kwargs
        //     ? (response.result.response as any).kwargs
        //     : undefined;

        let assistantMessageContent: string;
        if (response.result.response.lc_kwargs)
          assistantMessageContent = response.result.response.lc_kwargs.content;
        else if ((response.result.response as any).kwargs)
          assistantMessageContent = (response.result.response as any).kwargs
            .content;
        // const leads = processLeads(response.result.json, isUseProspector);
        const leads = JSON.stringify(response.result.json) ?? '';

        // Generate a unique ID for the assistant's response and add it to the messages
        const assistantMessageId = generateMessageId();
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: assistantMessageId,
            role: 'assistant',
            message: assistantMessageContent,
            json: leads,
          },
        ]);
        setNewMessageIds((prev) => new Set([...prev, assistantMessageId])); // Track for animations
      } catch (error) {
        // If there's an error, log it and display a generic error message to the user
        console.error('Error during message processing:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: generateMessageId(),
            role: 'assistant',
            message:
              'Sorry, I am unable to process your request at the moment. Please try again later.',
            json: '',
          },
        ]);
      } finally {
        // Scroll the chat to the bottom and stop the "thinking" indicator
        scrollToBottom();
        setThinking(false);
      }
    },
    [
      currentMessage,
      messages,
      // handleChangePlatformUX,
      generateTitle,
      currentChatID,
      setChatHistory,
      setCurrentChatID,
      scrollToBottom,
    ]
  );

  // Load messages when chat ID changes
  useEffect(() => {
    if (currentChatID) {
      fetchMessages(currentChatID);
    } else {
      setMessages([]);
    }
  }, [currentChatID, fetchMessages]);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Memoized messages to prevent unnecessary re-renders
  const renderedMessages = useMemo(() => {
    return messages.map((message) => (
      <Box key={message.id} width="100%">
        <RenderMessage
          id={message.id}
          role={message.role}
          message={message.message}
          json={message.json}
          animate={newMessageIds.has(message.id)}
        />
      </Box>
    ));
  }, [messages, newMessageIds]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '87vh',
        marginX: 12,
      }}
    >
      {currentChatID.length === 0 && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          flex="1"
        >
          <Typography sx={{ marginRight: 1 }}>Research ICPs</Typography>
          <IOSSwitch
            checked={isResearchMode}
            onChange={() =>
              setAlchemystStoreState({ isResearchMode: !isResearchMode })
            }
            disabled={thinking}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
      )}
      {loadingHistory && currentChatID !== '' ? (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {messages.length > 0 ? (
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
              <Stack spacing={2}>
                {renderedMessages}
                {thinking && (
                  <Box width="100%">
                    {/* Display a "thinking" message while the AI processes the user input */}
                    <RenderMessage
                      id={generateMessageId()}
                      role={'assistant'}
                      message={'Thinking...'}
                      json=""
                      animate={true} // No need to animate "thinking" message
                    />
                  </Box>
                )}
                <div ref={chatEndRef} />
              </Stack>
            </Box>
          ) : (
            <InitialSuggestedQuestions onSend={handleSendMessage} />
          )}
        </>
      )}
      <Box sx={{ paddingTop: 1, position: 'sticky', bottom: 0 }}>
        <PromptInputBox
          value={currentMessage}
          setValue={setCurrentMessage}
          onSend={handleSendMessage}
          thinking={thinking}
          setThinking={setThinking}
          selectedTuners={selectedTuners}
          setSelectedTuners={setSelectedTuners}
        />
      </Box>
    </Box>
  );
};

export default ChatMessagesContainer;
